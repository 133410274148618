import Vector from './classes/vector.js';
import ws from './main.js';

const canvas = document.getElementById('game');
const {
  width
} = canvas;
const {
  height
} = canvas;
let mapFile;

const keyCodeMap = {
  '-1': 'Left Click',
  '-2': 'Right Click',
  '-3': 'Middle Click',
  8: 'backspace',
  9: 'tab',
  13: 'enter',
  16: 'shift',
  17: 'ctrl',
  18: 'alt',
  19: 'pausebreak',
  20: 'capslock',
  27: 'escape',
  32: 'space',
  33: 'pageup',
  34: 'pagedown',
  35: 'end',
  36: 'home',
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',
  43: '+',
  44: 'printscreen',
  45: 'insert',
  46: 'delete',
  112: 'f1',
  113: 'f2',
  114: 'f3',
  115: 'f4',
  116: 'f5',
  117: 'f6',
  118: 'f7',
  119: 'f8',
  120: 'f9',
  121: 'f10',
  122: 'f11',
  123: 'f12',
  144: 'numlock',
  145: 'scrolllock',
};
window.onresize = function() {
  resize();
};
window.onload = function() {
  resize();
  if (localStorage.keybinds) {
    if (localStorage.keybinds.split(",").length != 10) {
      localStorage.clear()
    }
  }
  if (localStorage.getItem('keybinds')) {
    keysInput = localStorage.getItem('keybinds').split(',');
    for (let i in document.getElementsByClassName('inputSelectItem')) {
      if (keysInput[i]) {
        let b = keyCodeMap[keysInput[i]];
        if (!b) {
          b = String.fromCharCode(keysInput[i]);
        }
        if (document.getElementsByClassName('inputSelectItem')[i] == 11) {
          continue;
        }
        document.getElementsByClassName('inputSelectItem')[i].innerHTML = b.charAt(0).toUpperCase() + b.slice(1);
      }
    }
  }
  if (localStorage.getItem('aim')) {
    aim = localStorage.getItem('aim');
    if (aim == 1) {
      document.getElementsByClassName('inputSelectItem')[8].innerHTML = 'Keyboard';
    }
  }
  if (localStorage.getItem('roomTransition')) {
    window.roomTransition = localStorage.getItem('roomTransition') == "true" ? true : false;
    document.getElementById('roomTransition').innerHTML = window.roomTransition;
  }
  document.addEventListener('keydown', (e) => {
    if (changingKey != null) {
      let b = keyCodeMap[e.keyCode];
      if (!b) {
        b = String.fromCharCode(e.keyCode);
      }
      if (b.trim()) {
        changingElement.innerHTML = b.charAt(0).toUpperCase() + b.slice(1);
        keysInput[changingKey] = e.keyCode;
      } else {
        changingElement.innerHTML = previousElementContent;
      }
      changingElement = null;
      changingKey = null;
      localStorage.setItem('keybinds', keysInput);
    } else if (!window.inMenu) {
      if (!states.chat.inChat) {
        if (e.repeat) return;
        if (e.keyCode == 85) {
          fov--;
        }
        if (e.keyCode == 73) {
          fov++;
        }
        if (fov < 1) {
          fov = 1;
        }
        if (fov > 13) {
          fov = 13;
        }
        if (e.keyCode == 79) {
          debug = !debug;
        }
        if (e.keyCode == 80) {
          states.settings.showChat = !states.settings.showChat;
          if (states.settings.showChat) {
            document.getElementById("chatui").style.display = "";
          } else {
            document.getElementById("chatui").style.display = "none";
          }
        }
        if (e.keyCode == 76) {
          states.settings.showLeaderboard = !states.settings.showLeaderboard;
          if (states.settings.showLeaderboard) {
            states.leaderboard.leaderboard.style.display = "";
          } else {
            states.leaderboard.leaderboard.style.display = "none";
          }
        }
        keys(e.keyCode, true);
      }
      if (e.keyCode == 13) {
        if (true /* hidden ui check */ ) {
          if (e.repeat) return;
          if (states.chat.inChat) {
            if (document.getElementById('textInput').value.length > 0) {
              if (document.getElementById('textInput').value == "/clear") {
                states.chat.messages = [];
                states.chat.chat.innerHTML = "";
              } else {
                ws.send(msgpack.encode({
                  e: 'message',
                  message: document.getElementById('textInput').value,
                }));
              }
            }
            document.getElementById('textInput').value = "";
            states.chat.cursor = states.chat.message.length;
            states.chat.inChat = false;
            document.getElementById('textInput').blur();
          } else {
            states.chat.inChat = true;
            document.getElementById('textInput').focus();
          }
        }
      }
      if (e.keyCode == 8) {
        if (states.chat.inChat) {
          if (states.chat.message.length > 0) {
            states.chat.message = states.chat.message.substring(0, states.chat.cursor - 1) + states.chat.message.substring(states.chat.cursor, states.chat.message.length);
            if (states.chat.cursor > 0) {
              states.chat.cursor--;
              states.animations.cursor = [states.time];
            }
          }
        }
      }
      if (e.keyCode == 37) {
        if (states.chat.inChat) {
          if (states.chat.cursor > 0) {
            states.chat.cursor--;
            states.animations.cursor = [states.time];
          }
        }
      }
      if (e.keyCode == 39) {
        if (states.chat.inChat) {
          if (states.chat.cursor < states.chat.message.length) {
            states.chat.cursor++;
            states.animations.cursor = [states.time];
          }
        }
      }
      if (e.keyCode == 32) {
        if (!states.chat.inChat) {
          if ('reward1' in states.animations) {
            states.animations.reward1[0] = states.time;
            states.animations.reward1[1]++;
          }
        }
      }
    }
  }, false);
  document.addEventListener('keyup', (e) => {
    if (!window.inMenu) {
      keys(e.keyCode, false);
    }
  }, false);
  document.addEventListener('mousemove', (p) => {
    let t = cursor.getBoundingClientRect();
    mousePos = new Vector((p.pageX - t.left) / scale, (p.pageY - t.top) / scale);
  }, false);
  document.getElementById('play').onclick = function() {
    if (loggedIn) {
      window.refreshInterval = 1 // setInterval(() => {
      ws.send(msgpack.encode({
        e: 'games',
      }));
      while (document.getElementById('games_list').firstChild) {
        document.getElementById('games_list').removeChild(document.getElementById('games_list').lastChild);
      }
      document.getElementById('mainScreen').style.display = 'none';
      document.getElementById('games').style.display = 'block';
      //}, 1000);
    }
  };
  document.getElementById('refresh').onclick = function() {
    ws.send(msgpack.encode({
      e: 'games',
    }));
  };
  document.getElementById('create').onclick = function() {
    document.getElementById('mainMenu').style.display = 'none';
    document.getElementById('createMenu').style.display = 'block';
  };
  document.getElementById('browser').onclick = function() {
    document.getElementById('mainMenu').style.display = 'none';
    document.getElementById('mapMenu').style.display = 'block';
    ws.send(msgpack.encode({
      e: 'maps',
      s: 0,
      o: document.getElementById('dropdown').selectedIndex,
      c: /* document.getElementById('searchInput').value */ '',
    }));
  };
  document.getElementById('loadMoreButton').onclick = function() {
    ws.send(msgpack.encode({
      e: 'maps',
      s: document.getElementsByClassName('mapButton').length,
      o: document.getElementById('dropdown').selectedIndex,
      c: /* document.getElementById('searchInput').value */ '',
    }));
  };
  document.getElementById('register').onclick = function() {
    let username = document.getElementById('username');
    let password = document.getElementById('password');
    if (password.value.length > 3) {
      ws.send(msgpack.encode({
        e: 'register',
        m: {
          username: username.value,
          password: SHA256(username.value + password.value),
        },
      }));
    } else {
      document.getElementById('info').innerHTML = 'the password must be longer than 3 characters';
    }
  };
  document.getElementById('login').onclick = function() {
    grecaptcha.ready(function() {
      grecaptcha.execute('6Ld2wFMaAAAAAIL8fjR5Bwg6kn3fP2t-b9NFoK_R', {
        action: 'submit'
      }).then(function(token) {
        let username = document.getElementById('username');
        let password = document.getElementById('password');
        ws.send(msgpack.encode({
          e: 'login',
          m: {
            username: username.value,
            password: SHA256(username.value + password.value)
          },
          t: token
        }));
      });
    })
  }
  document.getElementById('guest').onclick = function() {
    grecaptcha.ready(function() {
      grecaptcha.execute('6Ld2wFMaAAAAAIL8fjR5Bwg6kn3fP2t-b9NFoK_R', {
        action: 'submit'
      }).then(function(token) {
        let username = document.getElementById('username');
        let password = document.getElementById('password');
        ws.send(msgpack.encode({
          e: 'guest',
          t: token
        }));
      });
    });
  };
  document.getElementById('logout').onclick = function() {
    ws.send(msgpack.encode({
      e: 'logout',
    }));
    document.getElementById('info').innerHTML = '';
  };
  document.getElementById('dressing').onclick = function() {
    document.getElementById('mainScreen').style.display = 'none';
    document.getElementById('dressingScreen').style.display = 'block';
    ws.send(msgpack.encode({
      e: 'getStyle',
    }));
  };
  document.getElementById('colorPicker').addEventListener('change', watchColorPicker, false);

  function watchColorPicker(event) {
    ws.send(msgpack.encode({
      e: 'colorChange',
      c: hex2RGB(event.target.value),
    }));
  }
  document.onmousedown = function(e) {
    if (!window.inMenu) {
      let power = true;
      if (e.buttons == 1) {
        if (pointInRectangle(mousePos, new Vector(5, height - 35), new Vector(290, 30))) {
          power = false;
          e.preventDefault();
          states.chat.inChat = true;
          document.getElementById('textInput').focus();
        } else {
          if (states.chat.inChat) {
            power = false;
          }
          states.chat.inChat = false;
          canvas.focus();
        }
        for (let i in states.powers) {
          if (pointInRectangle(mousePos, toStone(i, states.powers.length), new Vector(41, 41))) {
            power = false;
            states.grabbing = true;
            states.grabbed = i;
          }
        }
      }
      if (power) {
        let t;
        if (e.button == 0) {
          t = -1;
        }
        if (e.button == 2) {
          t = -2;
        }
        if (e.button == 1) {
          t = -3;
        }
        keys(t, true);
      }
    } else if (changingKey != null) {
      if (e.button == 0) {
        changingElement.innerHTML = 'Left Click';
        keysInput[changingKey] = -1;
        changingElement = null;
        changingKey = null;
        localStorage.setItem('keybinds', keysInput);
      }
      if (e.button == 1) {
        changingElement.innerHTML = 'Middle Click';
        keysInput[changingKey] = -3;
        changingElement = null;
        changingKey = null;
        localStorage.setItem('keybinds', keysInput);
      }
      if (e.button == 2) {
        changingElement.innerHTML = 'Right Click';
        keysInput[changingKey] = -2;
        changingElement = null;
        changingKey = null;
        localStorage.setItem('keybinds', keysInput);
      }
    }
  };
  document.onmouseup = function(e) {
    if (!window.inMenu) {
      if (states.grabbing) {
        states.grabbing = false;
        let found = false;
        let target = -1;
        let powerss = [states.powers[0], states.powers[1]];
        for (let i in states.powers) {
          if (pointInRectangle(mousePos, toStone(i, states.powers.length), new Vector(41, 41))) {
            found = true;
            target = i;
          }
        }
        if (found && !states.datas.players[states.datas.infos.id].states.includes('Died')) {
          let temp = states.powers[target];
          states.powers[target] = states.powers[states.grabbed];
          states.powers[states.grabbed] = temp;
          let stone = toStone(target, states.powers.length);
          states.powers[target].targetPos = new Vector(stone.x + 20, stone.y + 20);
          if (states.powers[0] !== powerss[0]) {
            ws.send(msgpack.encode({
              e: 'powerChange',
              m: 0,
              i: states.powers[0].type,
            }));
          }
          if (states.powers[1] !== powerss[1]) {
            ws.send(msgpack.encode({
              e: 'powerChange',
              m: 1,
              i: states.powers[1].type,
            }));
          }
        }
        let stone = toStone(states.grabbed, states.powers.length);
        states.powers[states.grabbed].targetPos = new Vector(stone.x + 20, stone.y + 20);
      } else {
        let t;
        if (e.button == 0) {
          t = -1;
        }
        if (e.button == 2) {
          t = -2;
        }
        if (e.button == 1) {
          t = -3;
        }
        keys(t, false);
      }
    }
  };
  document.addEventListener('wheel', (e) => {
    if (!window.inMenu) {
      if (pointInRectangle(mousePos, new Vector(7, height - 300), new Vector(293, 263))) {
        states.chat.scroll -= e.deltaY / 5;
        if (states.chat.scroll < 0) {
          states.chat.scroll = 0;
        }
        if (states.chat.messages.height - 263 < states.chat.scroll) {
          if (states.chat.messages.height - 263 > 0) {
            states.chat.scroll = states.chat.messages.height - 263;
          } else {
            states.chat.scroll = 0;
          }
        }
      }
      if (pointInRectangle(mousePos, new Vector(3, 3), new Vector(293, 200))) {
        states.leaderboard.scroll -= e.deltaY / 5;
        if (states.leaderboard.scroll < 0) {
          states.leaderboard.scroll = 0;
        }
        if (states.leaderboard.scroll + 200 > states.leaderboard.leaderboard.height) {
          if (states.leaderboard.leaderboard.height - 200 > 0) {
            states.leaderboard.scroll = states.leaderboard.leaderboard.height - 200;
          } else {
            states.leaderboard.scroll = 0;
          }
        }
      }
    }
  }, false);
  document.getElementById('file').addEventListener('change', handleFiles, false);

  function handleFiles() {
    let jfile = this.files[0];
    let reader = new FileReader();
    reader.onloadend = function(evt) {
      if (evt.target.readyState == FileReader.DONE) {
        mapFile = JSON.parse(evt.target.result);
        document.getElementById('fileLabel').innerHTML = jfile.name;
      }
    };
    reader.readAsBinaryString(jfile);
  }
  document.getElementById('createGame').onclick = function() {
    if (document.getElementById("switch4").checked) {
      ws.send(msgpack.encode({
        e: 'createGame',
        s: {s:true},
      }));
    }else{
      let s = {
        n: document.getElementById('gameNameInput').value,
        p: document.getElementById('switch').checked,
        g: document.getElementById('switch1').checked,
        r: document.getElementById('switch2').checked,
        u: document.getElementById('switch3').checked,
      };
      if (document.getElementById('switch').checked) {
        s.pa = document.getElementById('passwordGameInput').value;
      }
      if (document.getElementById('switch2').checked) {
        let arr = [];
        if (!document.getElementById('shrinker').checked) {
          arr.push(0);
        }
        if (!document.getElementById('explosion').checked) {
          arr.push(1);
        }
        if (!document.getElementById('wall').checked) {
          arr.push(2);
        }
        if (!document.getElementById('meteor').checked) {
          arr.push(3);
        }
        if (!document.getElementById('refuel').checked) {
          arr.push(4);
        }
        if (!document.getElementById('feather').checked) {
          arr.push(5);
        }
        if (!document.getElementById('dash').checked) {
          arr.push(7);
        }
        if (!document.getElementById('lantern').checked) {
          arr.push(8);
        }
        if (!document.getElementById('shield').checked) {
          arr.push(6);
        }
        if (!document.getElementById('ghost').checked) {
          arr.push(9);
        }
        s.rd = arr;
      }
      ws.send(msgpack.encode({
        e: 'createGame',
        j: mapFile,
        s,
      }));
    }
  };
  let priv = document.getElementById('switch');
  priv.addEventListener('change', (event) => {
    if (event.target.checked) {
      document.getElementById('passwordGame').style.display = '';
    } else {
      document.getElementById('passwordGame').style.display = 'none';
    }
  });
  document.getElementById('switch2').addEventListener('change', (event) => {
    if (event.target.checked) {
      document.getElementById('restrictionPanel').style.display = '';
    } else {
      document.getElementById('restrictionPanel').style.display = 'none';
    }
  });
  document.getElementById('switch4').addEventListener('change', (event) => {
    if (!event.target.checked) {
      document.getElementById('gameName').style.display = '';
      document.getElementById('files').style.display = '';
      document.getElementById('perms').style.display = '';
      document.getElementById('private').style.display = '';
      document.getElementById('restriction').style.display = '';
      document.getElementById('upload').style.display = '';
    } else {
      document.getElementById('gameName').style.display = 'none';
      document.getElementById('files').style.display = 'none';
      document.getElementById('perms').style.display = 'none';
      document.getElementById('private').style.display = 'none';
      document.getElementById('passwordGame').style.display = 'none';
      document.getElementById('restriction').style.display = 'none';
      document.getElementById('restrictionPanel').style.display = 'none';
      document.getElementById('upload').style.display = 'none';
    }
  });

  document.getElementById('dropdown').addEventListener('change', (event) => {
    ws.send(msgpack.encode({
      e: 'maps',
      s: 0,
      o: document.getElementById('dropdown').selectedIndex,
      c: document.getElementById('searchInput').value,
    }));
  });

  document.getElementById('createGame2').onclick = function() {
    let s = {
      n: document.getElementById('gameNameInput2').value,
      p: document.getElementById('switch02').checked,
      g: document.getElementById('switch12').checked,
      r: document.getElementById('switch22').checked,
    };
    if (document.getElementById('switch02').checked) {
      s.pa = document.getElementById('passwordGameInput2').value;
    }
    if (document.getElementById('switch22').checked) {
      let arr = [];
      if (!document.getElementById('shrinker2').checked) {
        arr.push(0);
      }
      if (!document.getElementById('explosion2').checked) {
        arr.push(1);
      }
      if (!document.getElementById('wall2').checked) {
        arr.push(2);
      }
      if (!document.getElementById('meteor2').checked) {
        arr.push(3);
      }
      if (!document.getElementById('refuel2').checked) {
        arr.push(4);
      }
      if (!document.getElementById('feather2').checked) {
        arr.push(5);
      }
      if (!document.getElementById('dash2').checked) {
        arr.push(7);
      }
      if (!document.getElementById('lantern2').checked) {
        arr.push(8);
      }
      if (!document.getElementById('shield2').checked) {
        arr.push(6);
      }
      if (!document.getElementById('ghost2').checked) {
        arr.push(9);
      }
      s.rd = arr;
    }
    ws.send(msgpack.encode({
      e: 'createGame2',
      i: window.currentId,
      s,
    }));
  };
  priv = document.getElementById('switch02');
  priv.addEventListener('change', (event) => {
    if (event.target.checked) {
      document.getElementById('passwordGame2').style.display = '';
    } else {
      document.getElementById('passwordGame2').style.display = 'none';
    }
  });
  document.getElementById('switch22').addEventListener('change', (event) => {
    if (event.target.checked) {
      document.getElementById('restrictionPanel2').style.display = '';
    } else {
      document.getElementById('restrictionPanel2').style.display = 'none';
    }
  });
  document.getElementById('mainMenuBack').onclick = function() {
    clearInterval(window.refreshInterval);
    window.refreshInterval = null;
    document.getElementById('games').style.display = 'none';
    document.getElementById('mainScreen').style.display = 'block';
  };
  document.getElementById('createMenuBack').onclick = function() {
    document.getElementById('createMenu').style.display = 'none';
    document.getElementById('mainMenu').style.display = 'block';
  };
  document.getElementById('createMenu2Back').onclick = function() {
    document.getElementById('createMenu2').style.display = 'none';
    document.getElementById('mapMenu').style.display = 'block';
  };
  document.getElementById('mapMenuBack').onclick = function() {
    document.getElementById('mapMenu').style.display = 'none';
    document.getElementById('mainMenu').style.display = 'block';
  };
  document.getElementById('dressingMenuBack').onclick = function() {
    document.getElementById('dressingScreen').style.display = 'none';
    document.getElementById('mainScreen').style.display = 'block';
  };
  /* let elems = document.getElementsByClassName("inputSelectItem")
  for (let i in Array.from(document.getElementsByClassName("inputSelectItem"))) {
    Array.from(document.getElementsByClassName("inputSelectItem"))[i].onclick = keybindChange(Array.from(document.getElementsByClassName("inputSelectItem"))[i],i);
  } */
};

window.keybindChange = function(element, input) {
  if (changingKey != null && changingElement != null) {
    changingElement.innerHTML = previousElementContent;
  }
  previousElementContent = element.innerHTML;
  element.innerHTML = 'Press any Key';
  changingElement = element;
  changingKey = input;
};

window.searchInputFunc = function() {
  while (document.getElementById('maps_list').firstChild) {
    document.getElementById('maps_list').removeChild(document.getElementById('maps_list').lastChild);
  }
  ws.send(msgpack.encode({
    e: 'maps',
    s: 0,
    o: document.getElementById('dropdown').selectedIndex,
    c: document.getElementById('searchInput').value,
  }));
};

window.changeOption = function() {
  while (document.getElementById('maps_list').firstChild) {
    document.getElementById('maps_list').removeChild(document.getElementById('maps_list').lastChild);
  }
  ws.send(msgpack.encode({
    e: 'maps',
    s: 0,
    o: document.getElementById('dropdown').selectedIndex,
    c: document.getElementById('searchInput').value,
  }));
};

window.changeRoomAnimation = function() {
  window.roomTransition = !window.roomTransition;
  document.getElementById('roomTransition').innerHTML = window.roomTransition;
  localStorage.setItem("roomTransition", String(window.roomTransition));
}

function resize() {
  let winw = window.innerWidth;
  let winh = window.innerHeight;
  let xvalue = winw / width;
  let yvalue = winh / height;
  scale = xvalue;
  if (yvalue < xvalue) {
    scale = yvalue;
  }
  for (let i in gamed.childNodes) {
    if (gamed.childNodes[i].nodeType == 1) {
      gamed.childNodes[i].style.transform = `scale(${scale})`;
      gamed.childNodes[i].style.left = `${(winw - width) / 2}px`;
      gamed.childNodes[i].style.top = `${(winh - height) / 2}px`;
    }
  }
}

const distance = function(pos1, pos2) {
  return Math.sqrt(Math.pow(pos1.x - pos2.x, 2) + Math.pow(pos1.y - pos2.y, 2));
};
const pointInRectangle = function(pos, rectpos, rectsize) {
  return (pos.x > rectpos.x && pos.x < rectpos.x + rectsize.x && pos.y > rectpos.y && pos.y < rectpos.y + rectsize.y);
};

function resizeCanvas(_canvas, w, h) {
  // create a temporary canvas obj to cache the pixel data //
  let temp_cnvs = document.createElement('canvas');
  let temp_cntx = temp_cnvs.getContext('2d');
  // set it to the new width & height and draw the current canvas data into it //
  temp_cnvs.width = w;
  temp_cnvs.height = h;
  temp_cntx.drawImage(_canvas, 0, 0);
  // resize & clear the original canvas and copy back in the cached pixel data //
  _canvas.width = w;
  _canvas.height = h;
  _canvas.getContext('2d').drawImage(temp_cnvs, 0, 0);
}

function wrapText(context, text, x, y, maxWidth, lineHeight) {
  let words = text.split(' ');
  let line = '';

  for (let n = 0; n < words.length; n++) {
    let testLine = `${line + words[n]} `;
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = `${words[n]} `;
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}
/**
 *
 *  Secure Hash Algorithm (SHA256)
 *  http://www.webtoolkit.info/
 *
 *  Original code by Angel Marin, Paul Johnston.
 *
 * */
function SHA256(s) {
  let chrsz = 8;
  let hexcase = 0;

  function safe_add(x, y) {
    let lsw = (x & 0xFFFF) + (y & 0xFFFF);
    let msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
  }

  function S(X, n) {
    return (X >>> n) | (X << (32 - n));
  }

  function R(X, n) {
    return (X >>> n);
  }

  function Ch(x, y, z) {
    return ((x & y) ^ ((~x) & z));
  }

  function Maj(x, y, z) {
    return ((x & y) ^ (x & z) ^ (y & z));
  }

  function Sigma0256(x) {
    return (S(x, 2) ^ S(x, 13) ^ S(x, 22));
  }

  function Sigma1256(x) {
    return (S(x, 6) ^ S(x, 11) ^ S(x, 25));
  }

  function Gamma0256(x) {
    return (S(x, 7) ^ S(x, 18) ^ R(x, 3));
  }

  function Gamma1256(x) {
    return (S(x, 17) ^ S(x, 19) ^ R(x, 10));
  }

  function core_sha256(m, l) {
    let K = new Array(0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2);
    let HASH = new Array(0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19);
    let W = new Array(64);
    let a;
    let b;
    let c;
    let d;
    let e;
    let f;
    let g;
    let h;
    let i;
    let
      j;
    let T1;
    let
      T2;
    m[l >> 5] |= 0x80 << (24 - l % 32);
    m[((l + 64 >> 9) << 4) + 15] = l;
    for (let i = 0; i < m.length; i += 16) {
      a = HASH[0];
      b = HASH[1];
      c = HASH[2];
      d = HASH[3];
      e = HASH[4];
      f = HASH[5];
      g = HASH[6];
      h = HASH[7];
      for (let j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i];
        else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
        T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
        T2 = safe_add(Sigma0256(a), Maj(a, b, c));
        h = g;
        g = f;
        f = e;
        e = safe_add(d, T1);
        d = c;
        c = b;
        b = a;
        a = safe_add(T1, T2);
      }
      HASH[0] = safe_add(a, HASH[0]);
      HASH[1] = safe_add(b, HASH[1]);
      HASH[2] = safe_add(c, HASH[2]);
      HASH[3] = safe_add(d, HASH[3]);
      HASH[4] = safe_add(e, HASH[4]);
      HASH[5] = safe_add(f, HASH[5]);
      HASH[6] = safe_add(g, HASH[6]);
      HASH[7] = safe_add(h, HASH[7]);
    }
    return HASH;
  }

  function str2binb(str) {
    let bin = Array();
    let mask = (1 << chrsz) - 1;
    for (let i = 0; i < str.length * chrsz; i += chrsz) {
      bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
    }
    return bin;
  }

  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }

  function binb2hex(binarray) {
    let hex_tab = hexcase ? '0123456789ABCDEF' : '0123456789abcdef';
    let str = '';
    for (let i = 0; i < binarray.length * 4; i++) {
      str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
        hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
    }
    return str;
  }
  s = Utf8Encode(s);
  return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
}

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function getCookie(name) {
  let nameEQ = `${name}=`;
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
CanvasRenderingContext2D.prototype.roundRect = function(x, y, w, h, r) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  this.beginPath();
  this.moveTo(x + r, y);
  this.arcTo(x + w, y, x + w, y + h, r);
  this.arcTo(x + w, y + h, x, y + h, r);
  this.arcTo(x, y + h, x, y, r);
  this.arcTo(x, y, x + w, y, r);
  this.closePath();
  return this;
};

CanvasRenderingContext2D.prototype.drawBubble = function(x, y, w, h, radius, px, py) {
  let r = x + w;
  let b = y + h;
  if (py < y || py > y + h) {
    var con1 = Math.min(Math.max(x + radius, px - 10), r - radius - 20);
    var con2 = Math.min(Math.max(x + radius + 20, px + 10), r - radius);
  } else {
    var con1 = Math.min(Math.max(y + radius, py - 10), b - radius - 20);
    var con2 = Math.min(Math.max(y + radius + 20, py + 10), b - radius);
  }
  let dir;
  if (py < y) dir = 2;
  if (py > y) dir = 3;
  if (px < x && py >= y && py <= b) dir = 0;
  if (px > x && py >= y && py <= b) dir = 1;
  if (px >= x && px <= r && py >= y && py <= b) dir = -1;
  this.beginPath();
  this.moveTo(x + radius, y);
  if (dir == 2) {
    this.lineTo(con1, y);
    this.lineTo(px, py);
    this.lineTo(con2, y);
    this.lineTo(r - radius, y);
  } else this.lineTo(r - radius, y);
  this.quadraticCurveTo(r, y, r, y + radius);
  if (dir == 1) {
    this.lineTo(r, con1);
    this.lineTo(px, py);
    this.lineTo(r, con2);
    this.lineTo(r, b - radius);
  } else this.lineTo(r, b - radius);
  this.quadraticCurveTo(r, b, r - radius, b);
  if (dir == 3) {
    this.lineTo(con2, b);
    this.lineTo(px, py);
    this.lineTo(con1, b);
    this.lineTo(x + radius, b);
  } else this.lineTo(x + radius, b);
  this.quadraticCurveTo(x, b, x, b - radius);
  if (dir == 0) {
    this.lineTo(x, con2);
    this.lineTo(px, py);
    this.lineTo(x, con1);
    this.lineTo(x, y + radius);
  } else this.lineTo(x, y + radius);
  this.quadraticCurveTo(x, y, x + radius, y);
  this.closePath();
  return this
}

function toStone(index, length) {
  let x = width - 50;
  let mean = ((length - 1) / 2) * 55;
  let y = height / 2 - 22 + (index - 1) * 55 - mean;
  if (index < 2) {
    x = width - 110;
    y = height / 2 + (index * 55 - 50);
  }
  return new Vector(x, y);
}

function between(val, min, max) {
  let val1 = val;
  if (val1 < min) {
    val1 = min;
  }
  if (val1 > max) {
    val1 = max;
  }
  return val1;
}

function checkProfanityString(a) {
  let list = decodeURIComponent(escape(window.atob('Y3VudCB3aG9yZSBzaGl0IGZ1Y2sgZmFnZ290IG5pZ2dlciBuaWdnYSBkaWNrIHZhZ2luYSBtaW5nZSBjb2NrIHJhcGUgY3VtIHNleCB0aXRzIHBlbmlzIGNsaXQgcHVzc3kgbWVhdGN1cnRhaW4gaml6eiBwcnVuZSBkb3VjaGUgd2Fua2VyIGplcms='))).split(' ');
  for (let b = 0; b < list.length; ++b) {
    if (a.toLowerCase().indexOf(list[b]) > -1) {
      tmpString = '';
      for (let d = 0; d < list[b].length; ++d) {
        tmpString += '*';
      }
      a = a.replace(new RegExp(list[b], 'gi'), tmpString);
    }
  }
  return a;
}
window.addEventListener('contextmenu', (e) => {
  e.preventDefault();
}, false);

function keys(keycode, value) {
  for (let i in keysInput) {
    if (keycode == keysInput[i]) {
      ws.send(msgpack.encode({
        e: 'input',
        input: {
          keys: i,
          value: value,
        },
      }));
    }
  }
}

function loadImage(src) {
  let a = new Image();
  a.src = src;
  return a;
}

function hex2RGB(hex) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
}

function rgbToHex(rgb) {
  return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
}

function cmpArray(a, b) {
  if (b.length != a.length) return false;
  for (let i = 0; i < (a.length || 0); i++) {
    for (let j = 0; j < a[i].length; j++) {
      if (a[i][j] != b[i][j]) return false;
    }
  }
  return true;
}

export {
  keyCodeMap,
  resize,
  distance,
  pointInRectangle,
  resizeCanvas,
  wrapText,
  SHA256,
  setCookie,
  getCookie,
  toStone,
  between,
  checkProfanityString,
  keys,
  loadImage,
  rgbToHex,
  cmpArray,
};
