import Vector from './classes/vector';
import { toStone } from './utils';
import textures from './textures';

class Particle {
  constructor(type, time, x1, x2, y1, y2, dir1, dir2, speed1, speed2, friction, alpha, alphaDim, r, g, b, size, sizeChange, text = undefined) {
    this.type = type;
    this.time = time;
    this.pos = new Vector(Math.random() * (x2 - x1) + x1, Math.random() * (y2 - y1) + y1);
    let dirR = Math.random() * (dir2 - dir1) + dir1;
    let speedR = Math.random() * (speed2 - speed1) + speed1;
    this.vel = new Vector(Math.cos(dirR) * speedR, Math.sin(dirR) * speedR);
    this.friction = friction;
    this.alphaDim = alphaDim;
    this.alpha = alpha;
    this.color = [r, g, b];
    this.size = size;
    this.sizeChange = sizeChange;
    this.texture = text;
  }

  update(time) {
    this.time -= time;
    this.pos.x += this.vel.x * (time / 16);
    this.pos.y += this.vel.y * (time / 16);
    this.vel.x *= Math.pow(this.friction, (time / 16));
    this.vel.y *= Math.pow(this.friction, (time / 16));
    this.alpha -= this.alphaDim * (time / 16);
    this.size += this.sizeChange * (time / 16);
  }
}

class Power {
  constructor(type, index, length) {
    this.type = type;
    this.image = document.createElement('CANVAS');
    this.image.width = 41;
    this.image.height = 41;
    let contextI = this.image.getContext('2d');
    contextI.drawImage(textures.powers[this.type], 0, 0, 41, 41);
    this.sprite = PIXI.Sprite.from(this.image);
    //console.log(this.sprite);
    this.pos = toStone(index, length);
    let stone = toStone(index, length);
    this.targetPos = new Vector(stone.x + 20, stone.y + 20);
  }

  recreate(index, length) {
    let stone = toStone(index, length);
    this.targetPos = new Vector(stone.x + 20, stone.y + 20);
  }

  updatePixi() {
    this.sprite.x = this.pos.x + 2;
    this.sprite.y = this.pos.y + 2;
  }
}

export {
  Particle,
  Power,
};
