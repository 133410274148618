import Vector from './classes/vector';
import {
    between,
    pointInRectangle,
    toStone,
    drawBubble,
} from './utils';
import textures from './textures';

function easeInOutQuad(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
}

function smooth(val, offset, start, end, time) {
    if (val - offset < 0) {
        return easeInOutQuad(0, start, end - start, time);
    }
    if (val - offset > time) {
        return easeInOutQuad(time, start, end - start, time);
    }
    return easeInOutQuad(val - offset, start, end - start, time);
}

let pixiui = {};
pixiui.setup = function(states) {
    this.app = new PIXI.Application({ width: 1600, height: 900, resizeTo: document.getElementById("game"), backgroundAlpha: 0 });
    this.app.id = "pixi";
    PIXI.Ticker.targetFPMS = .03;
    document.getElementById("gamed").appendChild(this.app.view);
    for (let i in states.powers) {
        this.app.stage.addChild(states.powers[i].sprite);
        console.log(states.powers[i].sprite);
    }
    this.app.ticker.add((delta) => {
        //console.log(delta);

    })
}

function render(states) {
    cursor.style.cursor = 'default';
    // borders
    let pattern = context.createPattern(textures.background, 'repeat');
    context.save();
    context.translate(-states.camera.x * fov, -states.camera.y * fov);
    context.fillStyle = pattern;
    context.fillRect(states.camera.x * fov, states.camera.y * fov, width, height);
    if (states.datas.backgroundColor[0] == "hsl") {
        context.fillStyle = `hsl(${states.datas.backgroundColor[1]}, 75%, 40%)`;
    }
    else {
        context.fillStyle = `rgba(${states.datas.backgroundColor[0]}, ${states.datas.backgroundColor[1]}, ${states.datas.backgroundColor[2]}, ${states.datas.backgroundColor[3]})`;
    }
    context.fillRect(states.camera.x * fov, states.camera.y * fov, width, height);
    context.restore();
    // area
    // context.fillStyle = "#e7e7e7";
    // context.fillStyle = "rgb(245, 239, 220)";
    context.fillStyle = `rgba(${states.datas.areaColor[0]}, ${states.datas.areaColor[1]}, ${states.datas.areaColor[2]}, 1)`;
    context.fillRect(Math.round(width / 2 + (-states.camera.x) * fov), Math.round(height / 2 + (-states.camera.y) * fov), Math.round(states.datas.areaSize.x * fov), Math.round(states.datas.areaSize.y * fov));

    // obstacles
    context.fillStyle = pattern
    renderGroup(states.datas.objects, "Obstacle");
    if (states.datas.backgroundColor[0] == "hsl") {
        context.fillStyle = `hsl(${states.datas.backgroundColor[1]}, 75%, 40%)`;
    }
    else {
        context.fillStyle = `rgba(${states.datas.backgroundColor[0]}, ${states.datas.backgroundColor[1]}, ${states.datas.backgroundColor[2]}, ${states.datas.backgroundColor[3]})`;
    }
    renderGroup(states.datas.objects, "Obstacle");

    // teleporters
    let teleporters = states.datas.objects.teleporter;
    for (let i in teleporters) {
        let a = 1;
        let b = 1;
        if (teleporters[i].dir == 1) {
            a = 0;
        }
        if (teleporters[i].dir == 2) {
            b = 0;
        }
        if (teleporters[i].dir == 3) {
            a = 2;
        }
        if (teleporters[i].dir == 0) {
            b = 2;
        }
        let gradient = context.createLinearGradient(
            Math.round(width / 2 + ((teleporters[i].pos.x + (teleporters[i].size.x / 2 * a)) - states.camera.x) * fov),
            Math.round(height / 2 + ((teleporters[i].pos.y + (teleporters[i].size.y / 2 * b)) - states.camera.y) * fov),
            Math.round(width / 2 + ((teleporters[i].pos.x + (teleporters[i].size.x / 2 * (2 - a))) - states.camera.x) * fov),
            Math.round(height / 2 + ((teleporters[i].pos.y + (teleporters[i].size.y / 2 * (2 - b))) - states.camera.y) * fov),
        );
        if (states.datas.backgroundColor[0] == "hsl") {
            gradient.addColorStop(0, `hsl(${states.datas.backgroundColor[1]}, 75%, 40%)`);
        }
        else {
            gradient.addColorStop(0, `rgba(${240 + (states.datas.backgroundColor[0] - 240) * states.datas.backgroundColor[3]}, ${240 + (states.datas.backgroundColor[1] - 240) * states.datas.backgroundColor[3]}, ${240 + (states.datas.backgroundColor[2] - 240) * states.datas.backgroundColor[3]}, 1)`);
        }
        gradient.addColorStop(1, `rgba(${states.datas.areaColor[0]}, ${states.datas.areaColor[1]}, ${states.datas.areaColor[2]}, 1)`);
        context.fillStyle = gradient;
        context.fillRect(Math.round(width / 2 + ((teleporters[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((teleporters[i].pos.y) - states.camera.y) * fov), Math.round(teleporters[i].size.x * fov), Math.round(teleporters[i].size.y * fov));
    }
    context.fillStyle = '#b74038';
    renderGroup(states.datas.objects, "Lava");
    context.fillStyle = '#7cabd2';
    renderGroup(states.datas.objects, "Ice");
    context.fillStyle = 'rgb(0,202,0)';
    renderGroup(states.datas.objects, "Slime");
    let buttons = states.datas.objects.button;
    context.beginPath();
    context.fillStyle = '#494949';
    for (let i in buttons) {
        if (buttons[i].dir == 0) {
            context.moveTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x + 2) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x - 2) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x + 2) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
        }
        if (buttons[i].dir == 1) {
            context.moveTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y + 2) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y - 2) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
        }
        if (buttons[i].dir == 2) {
            context.moveTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x - 2) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x + 2) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
        }
        if (buttons[i].dir == 3) {
            context.moveTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y + 2) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov) + Math.round(buttons[i].size.x * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y - 2) * fov) + Math.round(buttons[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((buttons[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((buttons[i].pos.y) - states.camera.y + 2) * fov));
        }
    }
    context.fill();
    let switches = states.datas.objects.switch;
    for (let i in switches) {
        context.beginPath();
        context.fillStyle = '#494949';
        let high = 0;
        let high2 = 1;
        if (switches[i].switch) {
            high = 1;
            high2 = 0;
            context.fillStyle = '#747474';
        }
        if (switches[i].dir == 0) {
            context.moveTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y - high) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y - high2) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
        }
        if (switches[i].dir == 1) {
            context.moveTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x + high) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x + high2) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
        }
        if (switches[i].dir == 2) {
            context.moveTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y + high) * fov) + Math.round(switches[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y + high2) * fov) + Math.round(switches[i].size.y * fov));
        }
        if (switches[i].dir == 3) {
            context.moveTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x - high) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov) + Math.round(switches[i].size.x * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
            context.lineTo(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x - high2) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov) + Math.round(switches[i].size.y * fov));
        }
        context.fill();
        context.closePath();
        // context.fillRect(Math.round(width / 2 + ((switches[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((switches[i].pos.y) - states.camera.y) * fov), Math.round(switches[i].size.x * fov), Math.round(switches[i].size.y * fov));
    }
    let doors = states.datas.objects.door;
    for (let i in doors) {
        if (!doors[i].opened) {
            context.fillStyle = '#525252';
            context.fillRect(Math.round(width / 2 + ((doors[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((doors[i].pos.y) - states.camera.y) * fov), Math.round(doors[i].size.x * fov), Math.round(doors[i].size.y * fov));
            context.fillStyle = '#8c8c8c';
            context.fillRect(Math.round(width / 2 + ((doors[i].pos.x) - states.camera.x + 1) * fov), Math.round(height / 2 + ((doors[i].pos.y) - states.camera.y + 1) * fov), Math.round((doors[i].size.x - 2) * fov), Math.round((doors[i].size.y - 2) * fov));
        } else {
            context.strokeStyle = '#9d9d9d';
            context.lineWidth = 1 * fov;
            context.strokeRect(Math.round(width / 2 + ((doors[i].pos.x) - states.camera.x + 0.5) * fov), Math.round(height / 2 + ((doors[i].pos.y) - states.camera.y + 0.5) * fov), Math.round((doors[i].size.x - 1) * fov), Math.round((doors[i].size.y - 1) * fov));
        }
    }
    context.save();
    context.lineWidth = fov;
    context.setLineDash([25 * fov / 6, 40 * fov / 6]);
    context.lineDashOffset = states.time * fov / 6 / 20;
    context.lineCap = 'round';
    for (let i in doors) {
        for (let j in doors[i].linkIds) {
            let idd = doors[i].linkIds[j];
            let neg = false;
            if (idd.toString()[0] == '-') {
                idd = idd.toString().substring(1);
                neg = true;
            }
            for (let k in buttons) {
                if (buttons[k].linkId == idd) {
                    if (buttons[k].pressed ^ neg) {
                        context.strokeStyle = 'rgba(147, 142, 23, 0.5)';
                    } else {
                        context.strokeStyle = 'rgba(96, 98, 80, 0.5)';
                    }
                    context.beginPath();
                    context.moveTo(Math.round(width / 2 + ((doors[i].pos.x) - states.camera.x) * fov) + Math.round(doors[i].size.x * fov) / 2, Math.round(height / 2 + ((doors[i].pos.y) - states.camera.y) * fov) + Math.round(doors[i].size.y * fov) / 2);
                    context.lineTo(Math.round(width / 2 + ((buttons[k].pos.x) - states.camera.x) * fov) + Math.round(buttons[k].size.x * fov) / 2, Math.round(height / 2 + ((buttons[k].pos.y) - states.camera.y) * fov) + Math.round(buttons[k].size.y * fov) / 2);
                    context.stroke();
                }
            }
            for (let k in switches) {
                if (switches[k].linkId == idd) {
                    if (switches[k].switch ^ neg) {
                        context.strokeStyle = 'rgba(147, 142, 23, 0.5)';
                    } else {
                        context.strokeStyle = 'rgba(96, 98, 80, 0.5)';
                    }
                    context.beginPath();
                    context.moveTo(Math.round(width / 2 + ((doors[i].pos.x) - states.camera.x) * fov) + Math.round(doors[i].size.x * fov) / 2, Math.round(height / 2 + ((doors[i].pos.y) - states.camera.y) * fov) + Math.round(doors[i].size.y * fov) / 2);
                    context.lineTo(Math.round(width / 2 + ((switches[k].pos.x) - states.camera.x) * fov) + Math.round(switches[k].size.x * fov) / 2, Math.round(height / 2 + ((switches[k].pos.y) - states.camera.y) * fov) + Math.round(switches[k].size.y * fov) / 2);
                    context.stroke();
                }
            }
        }
    }
    context.restore();
    let blocks = states.datas.objects.block;
    for (let i in blocks) {
        if (blocks[i].layer == 0) {
            context.fillStyle = `rgb(${blocks[i].color[0]},${blocks[i].color[1]},${blocks[i].color[2]},${blocks[i].opacity})`;
            context.fillRect(Math.round(width / 2 + ((blocks[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((blocks[i].pos.y) - states.camera.y) * fov), Math.round(blocks[i].size.x * fov), Math.round(blocks[i].size.y * fov));
        }
    }
    let { particles } = states;
    for (let i in particles) {
        if (particles[i].type == 'circle') {
            context.beginPath();
            context.fillStyle = `rgba(${particles[i].color[0]},${particles[i].color[1]},${particles[i].color[2]},${particles[i].alpha})`;
            context.arc(Math.round(width / 2 + (particles[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (particles[i].pos.y - states.camera.y) * fov), Math.round(particles[i].size * fov), 0, Math.PI * 2, true);
            context.fill();
            context.closePath();
        } else if (particles[i].type == 'rectangle') {
            context.fillStyle = `rgba(${particles[i].color[0]},${particles[i].color[1]},${particles[i].color[2]},${particles[i].alpha})`;
            context.fillRect(Math.round(width / 2 + (particles[i].pos.x - states.camera.x - particles[i].size) * fov), Math.round(height / 2 + (particles[i].pos.y - states.camera.y - particles[i].size) * fov), Math.round(particles[i].size * 2 * fov), Math.round(particles[i].size * 2 * fov));
        } else if (particles[i].type == 'image') {
            context.globalAlpha = particles[i].alpha;
            context.drawImage(particles[i].texture, Math.round(width / 2 + (particles[i].pos.x - states.camera.x - particles[i].size) * fov), Math.round(height / 2 + (particles[i].pos.y - states.camera.y - particles[i].size) * fov), Math.round(particles[i].size * 2 * fov), Math.round(particles[i].size * 2 * fov));
            context.globalAlpha = 1;
        }
    }
    // entities
    let { entities } = states.datas;
    for (let i = 0; i < entities.length; i++) {
        if (/*pointInRectangle(
            new Vector(width / 2 + (entities[i].pos.x - states.camera.x) * fov, height / 2 + (entities[i].pos.y - states.camera.y) * fov),
            new Vector(-entities[i].radius * fov, -entities[i].radius * fov),
            new Vector(width + entities[i].radius * fov * 2, height + entities[i].radius * fov * 2),
        ) || */true) {
            if (entities[i].type == 'normal') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.normal, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'reverse') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.reverse, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'spike') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.spike, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'bouncer') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.bouncer, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'freezer') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.freezer, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'rotating') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.save();
                context.translate(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov + entities[i].radius * fov * 2, height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov + entities[i].radius * fov * 2);
                context.rotate(entities[i].angle + Math.PI / 2);
                context.drawImage(textures.enemies.rotating, -entities[i].radius * fov * 2, -entities[i].radius * fov * 2, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.restore();
            }
            if (entities[i].type == 'following') {
                context.beginPath();
                context.fillStyle = `rgba(0, 0, 0, ${0.02 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round((entities[i].radius + entities[i].region) * fov), 0, Math.PI * 2, true);
                context.fill();
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.following, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'monster') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.monster, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'taker') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.taker, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'disabler') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.disabler, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'decelerator') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.decelerator, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'accelerator') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.accelerator, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'megaAccelerator') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.megaAccelerator, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'harmless') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.harmless, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'contractor') {
                context.beginPath();
                if (entities[i].triggered) {
                    context.fillStyle = `rgba(50, 0, 200, ${0.1 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                } else {
                    context.fillStyle = `rgba(0, 0, 0, ${0.02 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                }
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round((entities[i].radius + entities[i].region) * fov), 0, Math.PI * 2, true);
                context.fill();
                if (entities[i].triggered) {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.contractor1, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                } else {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.contractor, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                }
            }
            if (entities[i].type == 'drainer') {
                context.beginPath();
                if (entities[i].draining) {
                    context.fillStyle = `rgba(217, 159, 0, ${0.1 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                } else {
                    context.fillStyle = `rgba(0, 0, 0, ${0.02 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                }
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round((entities[i].radius + entities[i].region) * fov), 0, Math.PI * 2, true);
                context.fill();
                if (entities[i].draining) {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.drainer, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                } else {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.drainer, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                }
            }
            if (entities[i].type == 'immune') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.immune, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'expander') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.expander, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'bomb') {
                context.beginPath();
                if (entities[i].exploding) {
                    context.fillStyle = `rgba(255, 0, 0, ${0.1 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                } else {
                    context.fillStyle = `rgba(0, 0, 0, ${0.02 * (entities[i].opacity == undefined ? 1 : entities[i].opacity)})`;
                }
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round((entities[i].radius + entities[i].region) * fov), 0, Math.PI * 2, true);
                context.fill();
                if (entities[i].phase) {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.bomb1, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                } else {
                    context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                    context.drawImage(textures.enemies.bomb, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
                }
            }
            if (entities[i].type == 'megaBouncer') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.megaBouncer, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'wavy') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.wavy, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'shooter') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.shooter, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'dropper') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.dropper, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'snek') {
              context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                for (let j in entities[i].states.reverse()) {
                    context.drawImage(textures.enemies.snekBody, Math.round(width / 2 + (entities[i].states[j].x - states.camera.x - entities[i].states[j].radius * 2) * fov), Math.round(height / 2 + (entities[i].states[j].y - states.camera.y - entities[i].states[j].radius * 2) * fov), Math.round(entities[i].states[j].radius * fov * 4), Math.round(entities[i].states[j].radius * fov * 4));
                }
                context.save();
                context.translate(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov + entities[i].radius * fov * 2, height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov + entities[i].radius * fov * 2);
                context.rotate(entities[i].dir);
                context.drawImage(textures.enemies.snek, -entities[i].radius * fov * 2, -entities[i].radius * fov * 2, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.restore();
            }
            if (entities[i].type == 'stutter') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.stutter, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'gravityUp') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.gravityUp, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'gravityLeft') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.gravityLeft, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'gravityRight') {
                context.globalAlpha = (entities[i].opacity == undefined ? 1 : entities[i].opacity);
                context.drawImage(textures.enemies.gravityRight, Math.round(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov), Math.round(entities[i].radius * fov * 4), Math.round(entities[i].radius * fov * 4));
            }
            if (entities[i].type == 'turretBullet') {
                context.beginPath();
                context.fillStyle = '#e23e2b';
                // context.fillRect(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov,  height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'meteorBullet') {
                context.beginPath();
                context.fillStyle = '#d29100';
                // context.fillRect(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov,  height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'enemyBullet') {
                context.beginPath();
                context.fillStyle = '#e23e2b';
                // context.fillRect(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov,  height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'path') {
                context.beginPath();
                context.fillStyle = 'rgba(62, 115, 218, 0.08)';
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'shield') {
                context.save();
                context.translate(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov));
                context.rotate(entities[i].dir);
                context.lineWidth = entities[i].size.y * fov * 2;
                context.lineCap = 'round';
                context.beginPath();
                context.strokeStyle = 'rgb(57, 57, 57)';
                context.moveTo(-entities[i].size.x * fov, 0);
                context.lineTo(entities[i].size.x * fov, 0);
                context.stroke();
                context.restore();
            }
            if (entities[i].type == 'healingGhost') {
                context.beginPath();
                context.fillStyle = 'rgb(119, 205, 92)';
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'playerBomb') {
                context.beginPath();
                context.fillStyle = "red";
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2);
                context.fill();
                context.beginPath();
                context.fillStyle = 'rgba(224, 224, 224, .5)';
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].blastRadius * fov), 0, Math.PI * 2);
                context.fill();
            }
            if (entities[i].type == 'tail') {
                context.beginPath();
                context.fillStyle = 'rgba(84, 69, 38, 0.61)';
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'frostEntity') {
                context.beginPath();
                context.globalAlpha = entities[i].opacity;
                context.fillStyle = 'rgba(0, 198, 210, 0.3)';
                // context.fillRect(width / 2 + (entities[i].pos.x - states.camera.x - entities[i].radius * 2) * fov,  height / 2 + (entities[i].pos.y - states.camera.y - entities[i].radius * 2) * fov, entities[i].radius * fov * 4, entities[i].radius * fov * 4);
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == 'restZone') {
                context.beginPath();
                context.fillStyle = 'rgba(160, 118, 172, 0.47)';
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2, true);
                context.fill();
                context.closePath();
            }
            if (entities[i].type == "blueFrisbeeEntity") {
                context.beginPath();
                context.fillStyle = "blue";
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2)
                context.fill();
            }
            if (entities[i].type == "redFrisbeeEntity") {
                context.beginPath();
                context.fillStyle = "red";
                context.arc(Math.round(width / 2 + (entities[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (entities[i].pos.y - states.camera.y) * fov), Math.round(entities[i].radius * fov), 0, Math.PI * 2)
                context.fill();
            }
            context.globalAlpha = 1;
        }
    }
    let turrets = states.datas.objects.turret;
    for (let i in turrets) {
        let sayze = 2;
        context.translate(Math.round(width / 2 + ((turrets[i].pos.x + turrets[i].size.x / 2) - states.camera.x) * fov), Math.round(height / 2 + ((turrets[i].pos.y + turrets[i].size.y / 2) - states.camera.y) * fov));
        context.rotate(turrets[i].dir);
        context.fillStyle = '#525252';
        context.fillRect(-fov * sayze, -fov * sayze, 3.5 * fov * sayze, 2 * fov * sayze);
        context.resetTransform();
        context.beginPath();
        context.fillStyle = '#626262';
        // context.fillRect(Math.round(width / 2 + ((turrets[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((turrets[i].pos.y) - states.camera.y) * fov), Math.round(turrets[i].size.x * fov), Math.round(turrets[i].size.y * fov));
        context.arc(Math.round(width / 2 + ((turrets[i].pos.x) - states.camera.x) * fov) + Math.round(turrets[i].size.x * fov) / 2, Math.round(height / 2 + ((turrets[i].pos.y) - states.camera.y) * fov) + Math.round(turrets[i].size.x * fov) / 2, +Math.round(turrets[i].size.x * fov) / 2, 0, Math.PI * 2, true);
        context.fill();
        context.closePath();
    }
    for (let i in states.datas.players) {
        // context.fillStyle = "#804747";
        if (textures.hats[states.datas.players[i].hat].layer == 0) {
            context.save();
            context.translate(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov));
            context.rotate(states.datas.players[i].gravDir * Math.PI / 2);
            context.drawImage(textures.hats[states.datas.players[i].hat].texture, Math.round((states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[0]) * fov), Math.round((states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[1]) * fov), Math.round(states.datas.players[i].radius * fov * textures.hats[states.datas.players[i].hat].size[0]), Math.round(states.datas.players[i].radius * fov * textures.hats[states.datas.players[i].hat].size[1]));
            context.restore();
        }
        /*context.save();
        context.translate(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov));
        context.rotate(states.datas.players[i].gravDir * Math.PI / 2);
        context.drawImage(textures.hats["catEars1"].texture, Math.round((states.datas.players[i].radius * textures.hats["catEars1"].offset[0]) * fov), Math.round((states.datas.players[i].radius * textures.hats["catEars1"].offset[1]) * fov), Math.round(states.datas.players[i].radius * fov * textures.hats["catEars1"].size[0]), Math.round(states.datas.players[i].radius * fov * textures.hats["catEars1"].size[1]));
        context.restore();*/
        context.beginPath();
        context.fillStyle = `rgb(${states.datas.players[i].color[0]}, ${states.datas.players[i].color[1]}, ${states.datas.players[i].color[2]})`;
        if (states.datas.players[i].states.includes('Freeze')) {
            context.fillStyle = '#7cabd2';
        }
        if (states.datas.players[i].states.includes('Died')) {
            context.fillStyle = 'red';
        }
        context.arc(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov), states.datas.players[i].radius * fov, 0, Math.PI * 2, true);
        context.fill();
        context.lineWidth = 3;
        context.strokeStyle = `rgb(${(states.datas.players[i].color[0]) / 2}, ${(states.datas.players[i].color[1]) / 2}, ${(states.datas.players[i].color[2]) / 2})`
        context.arc(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov), states.datas.players[i].radius * fov, 0, Math.PI * 2, true);
        context.stroke();
        context.closePath();
        // context.fillRect(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x - states.datas.players[i].radius) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y - states.datas.players[i].radius) * fov), states.datas.players[i].radius * fov * 2, states.datas.players[i].radius * fov * 2);
        if (textures.hats[states.datas.players[i].hat].layer == 1) {
            context.save();
            context.translate(Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov));
            context.rotate(states.datas.players[i].gravDir * Math.PI / 2);
            context.drawImage(textures.hats[states.datas.players[i].hat].texture, Math.round((states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[0]) * fov), Math.round((states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[1]) * fov), Math.round(states.datas.players[i].radius * fov * textures.hats[states.datas.players[i].hat].size[0]), Math.round(states.datas.players[i].radius * fov * textures.hats[states.datas.players[i].hat].size[1]));
            context.restore();
        }
    }
    context.font = `${fov * 2.5}px Tahoma, Verdana, Segoe, sans-serif`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    for (let i in states.datas.players) {
        context.fillStyle = 'black';
        if (states.datas.players[i].states.includes('Freeze')) {
            context.fillStyle = '#41d1d9';
        }
        if (states.datas.players[i].states.includes('Died')) {
            context.fillStyle = 'red';
        }
        context.save();
        context.translate(
            between(
                Math.round(width / 2 + (states.datas.players[i].pos.x - states.camera.x) * fov),
                context.measureText(states.datas.players[i].name).width / 2 + 5,
                width - context.measureText(states.datas.players[i].name).width / 2 - 5
            ),
            between(
                Math.round(height / 2 + (states.datas.players[i].pos.y - states.camera.y) * fov),
                15,
                height - 15
            )
        );
        context.rotate(states.datas.players[i].gravDir * Math.PI / 2);
        let up = -states.datas.players[i].radius * fov * 1.5
        if ((states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[1] * fov) < up) {
            up = states.datas.players[i].radius * textures.hats[states.datas.players[i].hat].offset[1] * fov
        }
        context.fillText(states.datas.players[i].name, 0, up);
        context.restore();
    }
    blocks = states.datas.objects.block;
    for (let i in blocks) {
        if (blocks[i].layer == 1) {
            context.fillStyle = `rgb(${blocks[i].color[0]},${blocks[i].color[1]},${blocks[i].color[2]},${blocks[i].opacity})`;
            context.fillRect(Math.round(width / 2 + ((blocks[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((blocks[i].pos.y) - states.camera.y) * fov), Math.round(blocks[i].size.x * fov), Math.round(blocks[i].size.y * fov));
        }
    }
    let rewards = states.datas.objects.reward;
    for (let i in rewards) {
        context.drawImage(textures.powers[rewards[i].reward], Math.round(width / 2 + (rewards[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (rewards[i].pos.y - states.camera.y + Math.sin(states.time / 500) * 2) * fov), Math.round(fov * rewards[i].size.x), Math.round(fov * rewards[i].size.y));
    }
    let hatRewards = states.datas.objects.hatReward;
    for (let i in hatRewards) {
        context.drawImage(textures.hats[hatRewards[i].reward].texture, Math.round(width / 2 + (hatRewards[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (hatRewards[i].pos.y - states.camera.y + Math.sin(states.time / 500) * 2) * fov), Math.round(fov * hatRewards[i].size.x), Math.round(fov * hatRewards[i].size.y));
    }
    let coinsRewards = states.datas.objects.coinReward;
    for (let i in coinsRewards) {
        context.drawImage(textures.coin, Math.round(width / 2 + (coinsRewards[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (coinsRewards[i].pos.y - states.camera.y + Math.sin(states.time / 500) * 2) * fov), Math.round(fov * coinsRewards[i].size.x), Math.round(fov * coinsRewards[i].size.y));
    }
    let boxes = states.datas.objects.box;
    for (let i in boxes) {
        context.fillStyle = 'rgba(24, 24, 24, 0.6)';
        context.fillRect(Math.round(width / 2 + ((boxes[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((boxes[i].pos.y) - states.camera.y) * fov), Math.round(boxes[i].size.x * fov), Math.round(boxes[i].size.y * fov));
    }
    let gravityZones = states.datas.objects.gravityZone;
    context.save();
    context.setLineDash([5, 20]);
    context.lineCap = 'round';
    context.lineWidth = 3;
    for (let i in gravityZones) {
        let r = 0;
        let g = 0;
        let b = 0;
        if (gravityZones[i].dir == 1) {
            r = 255;
        }
        if (gravityZones[i].dir == 2) {
            b = 255;
        }
        if (gravityZones[i].dir == 3) {
            g = 255;
        }
        context.fillStyle = `rgba(${r},${g},${b},0.06)`;
        context.strokeStyle = `rgb(${r},${g},${b})`;
        context.fillRect(Math.round(width / 2 + ((gravityZones[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((gravityZones[i].pos.y) - states.camera.y) * fov), Math.round(gravityZones[i].size.x * fov), Math.round(gravityZones[i].size.y * fov));
        context.strokeRect(width / 2 + ((gravityZones[i].pos.x) - states.camera.x) * fov, height / 2 + ((gravityZones[i].pos.y) - states.camera.y) * fov, gravityZones[i].size.x * fov, gravityZones[i].size.y * fov);
    }
    context.restore();
    let fuelZones = states.datas.objects.fuelZone;
    context.beginPath();
    context.fillStyle = `rgba(196, 196, 196, 0.5)`;
    for (let i in fuelZones) {
        context.moveTo(Math.round(width / 2 + ((fuelZones[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((fuelZones[i].pos.y) - states.camera.y) * fov));
        context.rect(Math.round(width / 2 + ((fuelZones[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((fuelZones[i].pos.y) - states.camera.y) * fov), Math.round(fuelZones[i].size.x * fov), Math.round(fuelZones[i].size.y * fov))
    }
    context.fill();
    let texts = states.datas.objects.text;
    for (let i in texts) {
        context.lineWidth = 2 * fov / 6;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.font = `${5 * fov}px Russo One`;
        context.strokeStyle = "#000000";
        context.strokeText(texts[i].text, Math.round(width / 2 + (texts[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (texts[i].pos.y - states.camera.y) * fov));
        context.fillStyle = 'white';
        context.fillText(texts[i].text, Math.round(width / 2 + (texts[i].pos.x - states.camera.x) * fov), Math.round(height / 2 + (texts[i].pos.y - states.camera.y) * fov));
    }
    for (let i in blocks) {
        if (blocks[i].layer == 1) {
            context.fillStyle = `rgb(${blocks[i].color[0]},${blocks[i].color[1]},${blocks[i].color[2]},${blocks[i].opacity})`;
            context.fillRect(Math.round(width / 2 + ((blocks[i].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((blocks[i].pos.y) - states.camera.y) * fov), Math.round(blocks[i].size.x * fov), Math.round(blocks[i].size.y * fov));
        }
    }

    if (debug) {
        for (let i in states.datas.objects) {
            for (let j in states.datas.objects[i]) {
                context.strokeStyle = '#f6f41b';
                context.lineWidth = 1.5;
                context.strokeRect(Math.round(width / 2 + ((states.datas.objects[i][j].pos.x) - states.camera.x) * fov), Math.round(height / 2 + ((states.datas.objects[i][j].pos.y) - states.camera.y) * fov), Math.round(states.datas.objects[i][j].size.x * fov), Math.round(states.datas.objects[i][j].size.y * fov));
            }
        }
        context.font = '22px yes, yes1';
        context.fillStyle = 'black';
        context.textAlign = 'right';
        context.textBaseline = 'middle';
        context.fillText(`${window.fps} fps`, width, 12);
        context.fillText(`${window.byteSize || 0} bytes`, width - 60, 12);
        context.fillText(`${window.pingAverage} ms`, width - 130 - window.byteSize.toString().length * 5, 12);
    }

    context.fillStyle = '#ffff3d';
    context.fillRect(width / 2 - 300, height - 40, states.datas.infos.fuel / 10 * 600, 30);
    context.strokeStyle = 'rgba(0,0,0,0.5)';
    context.lineWidth = 6;
    context.strokeRect(width / 2 - 300, height - 40, 600, 30);

    if (states.combo) {
        let stone1 = toStone(0, states.powers.length);
        let stone2 = toStone(1, states.powers.length);
        context.fillStyle = "rgba(0, 111, 201, 0.75)";
        context.roundRect(stone1.x - 5, stone1.y - 5, 45 + 5 * 2, (stone2.y - stone1.y) + 45 + 5 * 2, 6).fill();
    }
    for (let i in states.powers) {
        context.fillStyle = 'rgba(237,255,252,0.9)';
        context.strokeStyle = 'rgb(18,18,18)';
        context.lineWidth = 4;
        let stone = toStone(i, states.powers.length);
        context.roundRect(stone.x, stone.y, 45, 45, 6).fill();
        if (i == 0) {
            let offset = (states.datas.infos.oneCooldown) * 45;
            context.fillStyle = 'rgba(228, 21, 21, 0.67)';
            context.fillRect(stone.x, stone.y + (45 - offset), 45, 45 - (45 - offset));
            offset = (states.datas.infos.oneHeat) * 45;
            context.fillStyle = 'rgba(21, 85, 228, 0.67)';
            context.fillRect(stone.x, stone.y + (45 - offset), 45, 45 - (45 - offset));
        }
        if (i == 1) {
            let offset = (states.datas.infos.twoCooldown) * 45;
            context.fillStyle = 'rgba(228, 21, 21, 0.67)';
            context.fillRect(stone.x, stone.y + (45 - offset), 45, 45 - (45 - offset));
            offset = (states.datas.infos.twoHeat) * 45;
            context.fillStyle = 'rgba(21, 85, 228, 0.67)';
            context.fillRect(stone.x, stone.y + (45 - offset), 45, 45 - (45 - offset));
        }
        context.roundRect(stone.x, stone.y, 45, 45, 6).stroke();
    }

    for (let i in states.powers) {
        states.powers[i].updatePixi();
        // if (states.powers[i].type in textures.powers) {
        //     context.drawImage(states.powers[i].image, Math.round(states.powers[i].pos.x + 2), Math.round(states.powers[i].pos.y + 2), 41, 41);
        // }
    }

    if ('reward1' in states.animations) {
        let posx = 0;
        let posy = 0;
        // Sometimes this conditions doesn't run
        if (states.animations.reward1[1] == 0) {
            context.fillStyle = 'rgba(56, 56, 56, 0.82)';
            context.fillRect(smooth(states.time - states.animations.reward1[0], 0, width / 2, width / 2 - 250, 500), smooth(states.time - states.animations.reward1[0], 0, height / 2, height / 2 - 250, 500), smooth(states.time - states.animations.reward1[0], 0, 0, 500, 500), smooth(states.time - states.animations.reward1[0], 0, 0, 390, 500));
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.font = 'bold 37px Russo One';
            context.fillStyle = `rgba(255,255,255,${smooth(states.time - states.animations.reward1[0], 3200, 0, 1, 500)})`;
            context.fillText('new power unlocked', width / 2, height / 2 + 50);
            context.font = '30px Russo One';
            context.fillStyle = `rgba(255,255,255,${smooth(states.time - states.animations.reward1[0], 3600, 0, 1, 500)})`;
            context.fillText('press space to continue', width / 2, height / 2 + 100);
            context.fillStyle = `rgba(165, 186, 189, ${smooth(states.time - states.animations.reward1[0], 3200, 0, 0.8, 300)})`;
            context.fillRect(width / 2 - 96, height / 2 - 100 - 96, 192, 192);
            posx = width / 2 + Math.cos(states.time / 430) * 3;
            posy = smooth(states.time - states.animations.reward1[0], 1500, -100, height / 2 - 100, 1200) + Math.cos(states.time / 250) * 6;
        } else if (states.animations.reward1[1] == 1) {
            context.fillStyle = 'rgba(56, 56, 56, 0.82)';
            context.fillRect(smooth(states.time - states.animations.reward1[0], 1000, width / 2 - 250, width / 2, 500), smooth(states.time - states.animations.reward1[0], 1000, height / 2 - 250, height / 2, 500), smooth(states.time - states.animations.reward1[0], 1000, 500, 0, 500), smooth(states.time - states.animations.reward1[0], 1000, 390, 0, 500));
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.font = 'bold 37px Russo One';
            context.fillStyle = `rgba(255,255,255,${smooth(states.time - states.animations.reward1[0], 500, 1, 0, 500)})`;
            context.fillText('new power unlocked', width / 2, height / 2 + 50);
            context.font = '30px Russo One';
            context.fillStyle = `rgba(255,255,255,${smooth(states.time - states.animations.reward1[0], 500, 1, 0, 500)})`;
            context.fillText('press space to continue', width / 2, height / 2 + 100);
            context.fillStyle = `rgba(165, 186, 189, ${smooth(states.time - states.animations.reward1[0], 200, 0.8, 0, 300)})`;
            context.fillRect(width / 2 - 96, height / 2 - 100 - 96, 192, 192);
            posx = width / 2 + Math.cos(states.time / 430) * 3;
            posy = smooth(states.time - states.animations.reward1[0], 0, height / 2 - 100, -100, 1200) + Math.cos(states.time / 250) * 6;
        }
        context.drawImage(textures.powers[states.animations.reward1[2]], posx - 90, posy - 90, 180, 180);
        if (states.animations.reward1[1] == 1) {
            if (states.time - states.animations.reward1[0] > 1500) {
                delete states.animations.reward1;
            }
        }
    }

    if (states.datas.players[states.datas.infos.id].states.includes('Died')) {
        context.lineWidth = 1;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.font = `bold ${30}px Russo One`;
        context.fillStyle = 'white';
        context.fillText('you are dead.', width / 2, 30);
        context.fillText("you can wait for someone to revive you or you can press 'r' to respawn", width / 2, 55);
        context.strokeStyle = 'black';
        context.strokeText('you are dead.', width / 2, 30);
        context.strokeText("you can wait for someone to revive you or you can press 'r' to respawn", width / 2, 55);
        if (document.getElementById("gameadsbanner").style.display !== "block") {
          GameAdsRenew("gameadsbanner")
          document.getElementById("gameadsbanner").style.display = "block";
        }
    }else {
      if (document.getElementById("gameadsbanner").style.display !== "none") {
        document.getElementById("gameadsbanner").style.display = "none";
      }
    }
    if (states.datas.backgroundColor[0] == "hsl") {
        states.datas.backgroundColor[1]++;
    }
}

function renderGroup(objects, type) {
    context.beginPath();
    context.save();
    context.translate(Math.round(-states.camera.x * fov), Math.round(-states.camera.y * fov));
    let normal = objects[type.toLowerCase()];
    let moving = objects['moving' + type];
    let circular = objects['circular' + type];
    let rotating = objects['rotating' + type];
    for (let i in normal) {
        context.moveTo(Math.round(states.camera.x * fov + width / 2 + ((normal[i].pos.x) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((normal[i].pos.y) - states.camera.y) * fov));
        context.rect(Math.round(states.camera.x * fov + width / 2 + ((normal[i].pos.x) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((normal[i].pos.y) - states.camera.y) * fov), Math.round(normal[i].size.x * fov), Math.round(normal[i].size.y * fov));
    }
    for (let i in moving) {
        context.moveTo(Math.round(states.camera.x * fov + width / 2 + ((moving[i].pos.x) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((moving[i].pos.y) - states.camera.y) * fov));
        context.rect(Math.round(states.camera.x * fov + width / 2 + ((moving[i].pos.x) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((moving[i].pos.y) - states.camera.y) * fov), Math.round(moving[i].size.x * fov), Math.round(moving[i].size.y * fov));
    }
    for (let i in circular) {
        context.moveTo(Math.round(states.camera.x * fov + width / 2 + ((circular[i].pos.x + circular[i].size.x / 2) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((circular[i].pos.y + circular[i].size.y / 2) - states.camera.y) * fov));
        context.arc(Math.round(states.camera.x * fov + width / 2 + ((circular[i].pos.x + circular[i].size.x / 2) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((circular[i].pos.y + circular[i].size.y / 2) - states.camera.y) * fov), Math.round(circular[i].radius * fov), 0, Math.PI * 2);
    }
    for (let i in rotating) {
        context.save();
        context.translate(Math.round(states.camera.x * fov + width / 2 + ((rotating[i].center.x) - states.camera.x) * fov), Math.round(states.camera.y * fov + height / 2 + ((rotating[i].center.y) - states.camera.y) * fov));
        context.rotate(rotating[i].angle * Math.PI / 180);
        context.rect(-Math.round(rotating[i].size.x / 2 * fov), -Math.round(rotating[i].size.y / 2 * fov), Math.round(rotating[i].size.x * fov), Math.round(rotating[i].size.y * fov));
        context.restore();
    }
    context.fill();
    context.restore();
}

export {
    easeInOutQuad,
    smooth,
    render,
    pixiui,
};
