import { loadImage } from './utils';

const textures = {};
textures.background = loadImage('../../textures/images/background11.png');
textures.enemies = {};
textures.coin = loadImage('../../textures/images/coin.png');
textures.enemies.normal = loadImage('../../textures/enemies/normal.svg');
textures.enemies.reverse = loadImage('../../textures/enemies/reverse.svg');
textures.enemies.spike = loadImage('../../textures/enemies/spike.svg');
textures.enemies.bouncer = loadImage('../../textures/enemies/bouncer.svg');
textures.enemies.freezer = loadImage('../../textures/enemies/freezer.svg');
textures.enemies.rotating = loadImage('../../textures/enemies/rotating.svg');
textures.enemies.following = loadImage('../../textures/enemies/following.svg');
textures.enemies.bomb = loadImage('../../textures/enemies/bomb.svg');
textures.enemies.bomb1 = loadImage('../../textures/enemies/bomb-1.svg');
textures.enemies.monster = loadImage('../../textures/enemies/monster.svg');
textures.enemies.taker = loadImage('../../textures/enemies/taker.svg');
textures.enemies.contractor = loadImage('../../textures/enemies/contractor.svg');
textures.enemies.contractor1 = loadImage('../../textures/enemies/contractor-1.svg');
textures.enemies.immune = loadImage('../../textures/enemies/immune.svg');
textures.enemies.expander = loadImage('../../textures/enemies/expander.svg');
textures.enemies.megaBouncer = loadImage('../../textures/enemies/megaBouncer.svg');
textures.enemies.wavy = loadImage('../../textures/enemies/wavy.svg');
textures.enemies.shooter = loadImage('../../textures/enemies/shooter.svg');
textures.enemies.snek = loadImage('../../textures/enemies/snake.svg');
textures.enemies.snekBody = loadImage('../../textures/enemies/snakeBody.svg');
textures.enemies.stutter = loadImage('../../textures/enemies/stutter.svg');
textures.enemies.gravityUp = loadImage('../../textures/enemies/gravityUp.svg');
textures.enemies.gravityLeft = loadImage('../../textures/enemies/gravityLeft.svg');
textures.enemies.gravityRight = loadImage('../../textures/enemies/gravityRight.svg');
textures.enemies.disabler = loadImage('../../textures/enemies/disabler.svg');
textures.enemies.harmless = loadImage('../../textures/enemies/harmless.svg');
textures.enemies.accelerator = loadImage('../../textures/enemies/accelerator.svg');
textures.enemies.decelerator = loadImage('../../textures/enemies/decelerator.svg');
textures.enemies.drainer = loadImage('../../textures/enemies/drainer.svg');
textures.powers = [];
textures.powers[-2] = loadImage('../../textures/enemies/default.svg');
textures.powers[-1] = loadImage('../../textures/enemies/default.svg');
textures.powers[0] = loadImage('../../textures/powers/shrinker.svg');
textures.powers[1] = loadImage('../../textures/powers/explosion.svg');
textures.powers[2] = loadImage('../../textures/powers/wall.svg');
textures.powers[3] = loadImage('../../textures/powers/meteor.svg');
textures.powers[4] = loadImage('../../textures/powers/refuel.svg');
textures.powers[5] = loadImage('../../textures/powers/feather.svg');
textures.powers[6] = loadImage('../../textures/powers/shield.svg');
textures.powers[7] = loadImage('../../textures/powers/dash.svg');
textures.powers[8] = loadImage('../../textures/powers/lantern.svg');
textures.powers[9] = loadImage('../../textures/powers/ghost.svg');
textures.powers[10] = loadImage('../../textures/powers/frost.svg');
textures.powers[11] = loadImage('../../textures/powers/shell.svg');
textures.powers[12] = loadImage('../../textures/powers/shell.svg');
textures.powers[13] = loadImage('../../textures/powers/feather.svg');
textures.particles = [];
textures.particles[0] = loadImage('../../textures/particles/heart.png');
textures.particles[1] = loadImage('../../textures/particles/sparkles.png');
textures.particles[2] = loadImage('../../textures/particles/greyPaw.png');
textures.particles[3] = loadImage('../../textures/particles/whitePaw.png');
textures.particles[4] = loadImage('../../textures/particles/pinkPaw.png');
textures.particles[5] = loadImage('../../textures/particles/blackHeart.png');
textures.particles[6] = loadImage('../../textures/particles/apple.png');
textures.hats = {};
textures.hats.none = {
  offset: [0, 0],
  size: [0, 0],
  texture: loadImage('../../textures/hats/none.png'),
  layer: 1,
};
textures.hats.catEars = {
  offset: [-1.09, -2.0],
  size: [2.2, 2.2],
  texture: loadImage('../../textures/hats/catEars.png'),
  layer: 1,
};
textures.hats.catEars1 = {
  offset: [-1.4, -2.0],
  size: [2.8, 2.8],
  texture: loadImage('../../textures/hats/catEars1.png'),
  layer: 0,
};
textures.hats.catEars111 = {
  offset: [-1.4, -2.0],
  size: [2.8, 2.8],
  texture: loadImage('../../textures/hats/catEars111.png'),
  layer: 0,
};
textures.hats.tophat = {
  offset: [-1.3, -2.4],
  size: [2.6, 2.6],
  texture: loadImage('../../textures/hats/topHat.png'),
  layer: 1,
};
textures.hats.guest = {
  offset: [0, 0],
  size: [0, 0],
  texture: loadImage('../../textures/hats/none.png'),
  layer: 1,
};
textures.hats.santa = {
  offset: [-1.4, -2.4],
  size: [3.4, 3.4],
  texture: loadImage('../../textures/hats/santa.png'),
  layer: 1,
};
textures.hats.militaryHat = {
  offset: [-1.55, -2],
  size: [3, 3],
  texture: loadImage('../../textures/hats/militaryHat.png'),
  layer: 1,
};
textures.hats.nookyHat = {
  offset: [-1.2, -2.8],
  size: [2.6, 2.6],
  texture: loadImage('../../textures/hats/nookyHat.png'),
  layer: 1,
};
textures.hats.ravelHat = {
  offset: [-1.2, -2.8],
  size: [2.6, 2.6],
  texture: loadImage('../../textures/hats/eggplant.png'),
  layer: 1,
};
textures.hats.wolf = {
  offset: [-1.5, -2.0],
  size: [3, 3],
  texture: loadImage('../../textures/hats/wolf.png'),
  layer: 1,
};
textures.hats.trumpHat = {
  offset: [-1.53, -2.1],
  size: [3.2, 3.2],
  texture: loadImage('../../textures/hats/trumpHat1.png'),
  layer: 1,
};
textures.hats.bunnyEars = {
  offset: [-1.4, -3],
  size: [3, 3],
  texture: loadImage('../../textures/hats/bunnyEars.png'),
  layer: 1,
};
textures.hats.crown = {
  offset: [-1.55, -2.65],
  size: [3.2, 3.2],
  texture: loadImage('../../textures/hats/crown.png'),
  layer: 1,
};
textures.hats.kite = {
  offset: [-0.8, -0.8],
  size: [1.6, 1.6],
  texture: loadImage('../../textures/hats/kite.png'),
  layer: 1,
};
textures.hats.sakura = {
  offset: [-1.05, -1.4],
  size: [2.0, 2.0],
  texture: loadImage('../../textures/hats/sakura.png'),
  layer: 1,
};
textures.hats.cowboy = {
  offset: [-1.6, -2.4],
  size: [3.2, 3.2],
  texture: loadImage('../../textures/hats/cowboy.png'),
  layer: 1,
};
textures.hats.party = {
  offset: [-1.36, -2.1],
  size: [2.65, 2.65],
  texture: loadImage('../../textures/hats/party.png'),
  layer: 1,
};
textures.hats.bimbo = {
  offset: [-1.2, -1.8],
  size: [2.4, 2.4],
  texture: loadImage('../../textures/hats/bimbo.png'),
  layer: 1,
};
textures.hats.uwu = {
  offset: [-2.8, -3.5],
  size: [5.6, 5.6],
  texture: loadImage('../../textures/hats/wowo.png'),
  layer: 1,
};
textures.hats.flowerHat = {
  offset: [-1.55, -2.4],
  size: [3.2, 3.2],
  texture: loadImage('../../textures/hats/flowerHat.png'),
  layer: 1,
};
textures.hats.panties = {
  offset: [-1.2, -1.2],
  size: [2.4, 2.4],
  texture: loadImage('../../textures/hats/panties.png'),
  layer: 1,
};
textures.hats.panties2 = {
  offset: [-1.3, -1.3],
  size: [2.6, 2.6],
  texture: loadImage('../../textures/hats/panties2.png'),
  layer: 1,
};
textures.hats.bimboPurple = {
  offset: [-1.45, -2.0],
  size: [2.9, 2.9],
  texture: loadImage('../../textures/hats/bimboPurple.png'),
  layer: 1,
};
textures.hats.bimboGreen = {
  offset: [-1.45, -2.0],
  size: [2.9, 2.9],
  texture: loadImage('../../textures/hats/bimboGreen.png'),
  layer: 1,
};
textures.hats.moustache = {
  offset: [-1.3, -1.6],
  size: [2.8, 2.8],
  texture: loadImage('../../textures/hats/moustache.png'),
  layer: 1,
};
textures.hats.onigiri = {
  offset: [-1.05, -1.9],
  size: [2.1, 2.1],
  texture: loadImage('../../textures/hats/onigiri.png'),
  layer: 1,
};
textures.hats.ramen = {
  offset: [-1.45, -2.5],
  size: [2.9, 2.9],
  texture: loadImage('../../textures/hats/ramen.png'),
  layer: 1,
};
textures.hats.salmonSushi = {
  offset: [-1.3618, -2.4],
  size: [2.8, 2.8],
  texture: loadImage('../../textures/hats/salmonSushi.png'),
  layer: 1,
};
textures.hats.horns = {
  offset: [-1.8, -2],
  size: [3.5, 3.5],
  texture: loadImage('../../textures/hats/horns.png'),
  layer: 1,
};
textures.hats.devil = {
  offset: [-1.8, -2],
  size: [3.5, 3.5],
  texture: loadImage('../../textures/hats/devil.png'),
  layer: 0,
};
textures.hats.taria = {
  offset: [-1.34, -2.1],
  size: [2.7, 2.7],
  texture: loadImage('../../textures/hats/taria.png'),
  layer: 1,
};

export default textures;
