import '../../assets/css/style.css';

import Vector from './classes/vector';
import { Particle, Power } from './classes.js';
import { render, pixiui } from './drawer.js';
import {
  getCookie, setCookie, resize, resizeCanvas, checkProfanityString, wrapText, rgbToHex, cmpArray,
} from './utils.js';
import textures from './textures';

const canvas = document.getElementById('game');
const context = canvas.getContext('2d');
window.canvas = canvas;
window.context = context;
const { width } = canvas;
const { height } = canvas;
window.width = width;
window.height = height;

if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = (function () {
    return window.webkitRequestAnimationFrame
      || window.mozRequestAnimationFrame
      || window.oRequestAnimationFrame
      || window.msRequestAnimationFrame
      || function (callback) {
        window.setTimeout(callback, 1000 / 60);
      };
  }());
}

window.inMenu = true;
window.fov = 6;
window.mousePos = new Vector(0, 0);
window.states = null;
window.debug = false;
window.loggedIn = false;
window.keysInput = [
  87, 65, 83, 68, 16, 32, -1, -2, -3, 82
];
window.dir = [1, 0];
let camera = new Vector(0, 0);
window.scale = 1;
let transition = 0;
let transitionTime = 300;
let times = [];
window.roomTransition = true;
window.refreshInterval = null;

let previousAimPos = [];
/*  38: "up",
  40: "down",
  37: "left",
  39: "right",
  87: "up",
  83: "down",
  65: "left",
  68: "right",
  82: "respawn",
  16: "shift"
} */
window.previousElementContent = null;
window.changingElement = null;
const objectTypeMap = ["rotatingLava", "movingLava", "movingObstacle", "movingIce", "movingSlime"];

window.changingKey = null;
let ws = new WebSocket(location.origin.replace(/^http/, 'ws'));
export default ws;
ws.binaryType = "arraybuffer";

ws.addEventListener('open', (event) => {
  console.log(getCookie('session'));
  if (getCookie('session')) {
    ws.send(msgpack.encode({
      e: 'session',
      cookie: getCookie('session'),
    }));
  }
});

window.totalSize = 0;
window.mapUpdates = 0;
window.byteSize = 0;

window.pingCount = 0;
window.totalPing = 0;
window.pingAverage = 0;
ws.addEventListener("ping", (ms) => {
  window.pingCount++;
  window.totalPing += ms;
  window.pingAverage = window.totalPing / window.pingCount;
})
ws.onmessage = (e) => {
  let msg = msgpack.decode(new Uint8Array(e.data));
  switch (msg.e) {
    case 'join':
      if (msg.m == 0) {
        clearInterval(window.refreshInterval);
        window.refreshInterval = null;
        window.inMenu = false;
        menu.style.display = 'none';
        gamed.style.display = 'inline-block';
        states = stateObject();
        states.updateStates(msg.i.states);
        states.initMap(msg.i.map);
        states.updatePlayerList(msg.i.playerList);
        for (let i in msg.i.powers) {
          states.powers.push(new Power(msg.i.powers[i], i, msg.i.powers.length));
        }
        pixiui.setup(states);
        resize();
      } else if (msg.m == 1) {
        let x = document.getElementById('loginInputs');
        x.classList.remove('shake');
        setTimeout("document.getElementById('loginInputs').className += 'shake';", 100);
        console.error('error');
      }
      break;
    case 'leave':
      window.inMenu = true;
      menu.style.display = '';
      gamed.style.display = 'none';
      break;
    case 'games':
      while (document.getElementById('games_list').firstChild) {
        document.getElementById('games_list').removeChild(document.getElementById('games_list').lastChild);
      }
      for (let i in msg.g) {
        if (Array.from(document.getElementsByClassName('gameButton')).filter((button) => button.gameId == msg.g[i].id).length == 0) {
          let tag = document.createElement('div');
          let text = document.createTextNode(msg.g[i].name);
          tag.appendChild(text);
          let br = document.createElement('br');
          tag.appendChild(br);
          let text1;
          if (msg.g[i].private) {
            text1 = document.createTextNode(`${msg.g[i].players}/${msg.g[i].capacity} players [private]`);
          } else {
            text1 = document.createTextNode(`${msg.g[i].players}/${msg.g[i].capacity} players`);
          }
          tag.appendChild(text1);
          br = document.createElement('br');
          tag.appendChild(br);
          let span = document.createElement('span');
          let text2 = document.createTextNode(`${msg.g[i].mapName} by ${msg.g[i].creator}`);
          span.style.fontSize = '14px';
          span.appendChild(text2);
          tag.appendChild(span);
          tag.classList.add('gameButton');
          tag.gameId = msg.g[i].id;
          tag.private = msg.g[i].private;
          tag.addEventListener('click', function () {
            if (this.private) {
              let password = prompt('password:');
              ws.send(msgpack.encode({
                e: 'join',
                g: this.gameId,
                p: password,
              }));
            } else {
              ws.send(msgpack.encode({
                e: 'join',
                g: this.gameId,
              }));
            }
          });
          document.getElementById('games_list').appendChild(tag);
        }
      }
      break;
    case 'maps':
      for (let i in msg.m) {
        if (Array.from(document.getElementsByClassName('mapButton')).filter((button) => button.id == msg.m[i].id).length == 0) {
          let tag = document.createElement('div');
          let text = document.createTextNode(`"${msg.m[i].name}" by ${msg.m[i].creator},  `);
          tag.appendChild(text);
          let tag2 = document.createElement('div');
          let text2 = document.createTextNode(`${msg.m[i].plays} plays `);
          tag2.style.fontSize = '14px';
          tag2.style.right = '5px';
          tag2.style.display = 'inline';
          tag2.appendChild(text2);
          tag.appendChild(tag2);
          let tag3 = document.createElement('button');
          let text3 = document.createTextNode('load');
          tag3.appendChild(text3);
          tag3.onclick = function () {
            document.getElementById('mapMenu').style.display = 'none';
            document.getElementById('createMenu2').style.display = 'block';
            window.currentId = this.parentElement.id;
            document.getElementById('fileLabel2').innerHTML = this.parentElement.name;
          };
          tag.appendChild(tag3);
          if (msg.m[i].own) {
            let tag4 = document.createElement('button');
            let text4 = document.createTextNode('delete');
            tag4.appendChild(text4);
            tag4.onclick = function () {
              if (confirm("are you sure?")) {
                ws.send(msgpack.encode({
                  e: 'delete',
                  m: this.parentElement.id,
                }));
                while (document.getElementById('maps_list').firstChild) {
                  document.getElementById('maps_list').removeChild(document.getElementById('maps_list').lastChild);
                }
              }
              ws.send(msgpack.encode({
                e: 'maps',
                s: 0,
                o: document.getElementById('dropdown').selectedIndex,
              }));
            };
            tag.appendChild(tag4);
          }
          tag.classList.add('mapButton');
          tag.id = msg.m[i].id;
          tag.name = msg.m[i].name;
          tag.own = msg.m[i].own;
          document.getElementById('maps_list').appendChild(tag);
        }
      }
      break;
    case 'create':
      if (msg.m == 1) {
        document.getElementById('createInfo').innerHTML = msg.t;
      }
      break;
    case 'power':
      for (let i in states.powers) {
        states.powers[i].recreate(i, states.powers.length + msg.m.length);
      }
      let { length } = states.powers;
      for (let i in msg.m) {
        states.powers.push(new Power(msg.m[i], i + length, msg.m.length + length));
      }
      break;
    case 'updateStates':
      states.updateStates(msg.m);
      break;
    case 'initMap':
      states.particles = [];
      states.initMap(msg.m);
      states.changeMap();
      window.totalSize = 0;
      window.mapUpdates = 0;
      window.byteSize = 0;
      break;
    case 'updateMap':
      states.updateMap(msg.m);
      window.mapUpdates++;
      window.totalSize += (new Uint8Array(e.data)).byteLength;
      window.byteSize = Math.round(window.totalSize / window.mapUpdates);
      if (isNaN(window.byteSize) || window.byteSize == undefined) {
        window.byteSize = 0;
        window.mapUpdates = 0;
        window.totalSize = 0;
      }
      break;
    case 'particles':
      for (let i in msg.m) {
        let particule = msg.m[i];
        if (particule.type == 'shrinking') {
          for (let j = 0; j < 13; j++) {
            states.particles.push(new Particle('circle', 1000000, particule.x, particule.y, particule.x, particule.y, Math.PI, Math.PI * 2, 0.5, 1, 0.9, 1, 0.01, 140, 0, 170, 2, -0.1));
          }
        }
        /* for (let j = 0; j < particule.number; j++) {
          states.particles.push(new Particle(particule.type, particule.time, particule.x1, particule.x2, particule.y1, particule.y2, particule.dir1, particule.dir2, particule.speed1, particule.speed2, particule.friction, particule.alpha, particule.alphaDim, particule.r, particule.g, particule.b, particule.size, particule.sizeChange))
        } */
      }
      break;
    case 'reward':
      states.animations.reward1 = [states.time, 0, msg.m];
      break;
    case 'hatReward':
      states.animations.hatReward1 = [states.time, 0, msg.m];
      break;
    case 'coinReward':
      states.animations.coinReward1 = [states.time, 0, msg.m];
      break;
    case 'message':
      let toScroll = states.chat.chat.scrollHeight - states.chat.chat.clientHeight <= states.chat.chat.scrollTop;
      msg.m.time = Date.now();
      states.chat.messages.push(msg.m)
      let m = checkProfanityString(msg.m.m);
      m = m.replaceAll("\n", "<br />")
      let text = `${msg.m.s}: ${m}`;
      let otherId = "normal";
      if (msg.m.r == -1) {
        otherId = "guest";
      } else if (msg.m.r == 1) {
        otherId = "dev";
      } else if (msg.m.r == 2) {
        otherId = "mod";
      } else if (msg.m.r == -2) {
        otherId = "discord";
      }
      if (states.chat.chat.innerHTML != "") {
        states.chat.chat.innerHTML += "<br>";
      }
      let div = document.createElement('div');
      div.innerHTML = `<span class="chat chat-${otherId}"></span>`;
      let span = div.firstChild;
      span.innerText = text;
      states.chat.chat.appendChild(span);
      if (toScroll) {
        states.chat.chat.scrollTop = states.chat.chat.scrollHeight - states.chat.chat.clientHeight;
      }
      break;
    case 'result':
      if (msg.m == 0) {
        document.getElementById('loginInputs').style.display = 'none';
        document.getElementById('logoutInputs').style.display = 'block';
        setCookie('session', msg.cookie, 7);
        document.getElementById('info').innerHTML = msg.t;
        loggedIn = true;
      } else if (msg.m == 1) {
        document.getElementById('info').innerHTML = msg.t;
      }
      break;
    case 'logout':
      document.getElementById('loginInputs').style.display = 'block';
      document.getElementById('logoutInputs').style.display = 'none';
      loggedIn = false;
      break;
    case 'style':
      document.getElementById('colorPicker').value = rgbToHex(msg.c);
      document.getElementById('hatSelection').innerHTML = '';
      for (let i in msg.h) {
        let element = document.createElement('div');
        element.classList.add('hatBox');
        let image = document.createElement('img');
        image.src = textures.hats[msg.h[i]].texture.src;
        image.classList.add('hatImage');
        if (msg.h[i] == msg.s) {
          image.style.backgroundColor = 'rgba(48, 147, 48, 0.4)';
        }
        image.name = msg.h[i];
        image.onclick = function () {
          ws.send(msgpack.encode({
            e: 'hatChange',
            c: this.name,
          }));
        };
        element.appendChild(image);
        let textElement = document.createElement('div');
        let text = document.createTextNode(msg.h[i]);
        textElement.appendChild(text);
        element.appendChild(textElement);
        document.getElementById('hatSelection').appendChild(element);
      }
      break;
    case 'combo':
      states.combo = msg.m
      break;
    case 'areaListUpdate':
      states.updatePlayerList(msg.m);
      break;
    case 'areaListDelete':
      states.removeFromPlayerList(msg.m);
      break;
    default:
      console.log(e);
      ws.close();
      break;
  }
};
ws.onclose = (e) => {
  console.log('closed', e);
};

function stateObject() {
  let obj = {};
  obj.datas = {};
  obj.particles = [];
  obj.camera = new Vector(0, 0);
  obj.time = 0;
  obj.animations = {};
  obj.animations.cursor = [obj.time];

  obj.powers = [];
  obj.grabbing = false;
  obj.grabbed = -1;
  obj.combo = false;

  obj.chat = {};
  obj.chat.inChat = false;
  obj.chat.message = '';
  obj.chat.cursor = 0;
  obj.chat.chat = document.getElementById('chat');
  obj.chat.messages = []

  obj.leaderboard = {}
  obj.leaderboard.leaderboard = document.getElementById("leaderboard");
  obj.leaderboard.scroll = 0;

  obj.settings = {}
  obj.settings.showChat = true;
  obj.settings.showLeaderboard = true;

  obj.update = function (time) {
    this.time += time;
    if (obj.grabbing) {
      states.powers[obj.grabbed].targetPos = mousePos;
    }
    for (let i in this.particles) {
      this.particles[i].update(time);
    }
    this.particles = this.particles.filter((x) => (x.alpha > 0 && x.size > 0 && x.time > 0));
    for (let i in this.powers) {
      let diffx = (this.powers[i].targetPos.x - this.powers[i].pos.x - 20) * 0.2;
      this.powers[i].pos.x += (this.powers[i].targetPos.x - this.powers[i].pos.x - 20) * 0.2;
      if (Math.abs(diffx) < 0.01) {
        this.powers[i].pos.x = this.powers[i].targetPos.x - 20;
      }
      let diffy = (this.powers[i].targetPos.y - this.powers[i].pos.y - 20) * 0.2;
      this.powers[i].pos.y += (this.powers[i].targetPos.y - this.powers[i].pos.y - 20) * 0.2;
      if (Math.abs(diffy) < 0.01) {
        this.powers[i].pos.y = this.powers[i].targetPos.y - 20;
      }
    }
    for (let i in this.datas.players) {
      if (this.datas.players[i].states.includes('jetpack')) {
        let xpos1, xpos2, ypos1, ypos2;
        if (this.datas.players[i].gravDir == 0) {
          xpos1 = this.datas.players[i].pos.x - 2;
          xpos2 = this.datas.players[i].pos.x + 2;
          ypos1 = this.datas.players[i].pos.y + this.datas.players[i].radius * 0.5;
          ypos2 = this.datas.players[i].pos.y + this.datas.players[i].radius * 0.5;
        }
        if (this.datas.players[i].gravDir == 1) {
          xpos1 = this.datas.players[i].pos.x - this.datas.players[i].radius * 0.5;
          xpos2 = this.datas.players[i].pos.x - this.datas.players[i].radius * 0.5;
          ypos1 = this.datas.players[i].pos.y - 2;
          ypos2 = this.datas.players[i].pos.y + 2;
        }
        if (this.datas.players[i].gravDir == 2) {
          xpos1 = this.datas.players[i].pos.x - 2;
          xpos2 = this.datas.players[i].pos.x + 2;
          ypos1 = this.datas.players[i].pos.y - this.datas.players[i].radius * 0.5;
          ypos2 = this.datas.players[i].pos.y - this.datas.players[i].radius * 0.5;
        }
        if (this.datas.players[i].gravDir == 3) {
          xpos1 = this.datas.players[i].pos.x + this.datas.players[i].radius * 0.5;
          xpos2 = this.datas.players[i].pos.x + this.datas.players[i].radius * 0.5;
          ypos1 = this.datas.players[i].pos.y - 2;
          ypos2 = this.datas.players[i].pos.y + 2;
        }
        let logisticFuelPercent = 1 / (1 + Math.exp(-(this.datas.players[i].fuel - 5)));
        for (let j = 0; j < 8; j++) {
          this.particles.push(new Particle('circle', 10000, xpos1, xpos2, ypos1, ypos2, 0, Math.PI, 0.15, 0.15, 1, 0.5, 0.01, (1 - logisticFuelPercent) * 156, logisticFuelPercent * 86 + 20, logisticFuelPercent * 133 + 23, 1, 0.01));
        }
      }
      if (this.datas.players[i].states.includes('Feather')) {
        if (Math.random() < 0.05) {
          this.particles.push(new Particle('circle', 10000, this.datas.players[i].pos.x, this.datas.players[i].pos.x, this.datas.players[i].pos.y, this.datas.players[i].pos.y, 0, Math.PI, 0.05, 0.06, 1, 0.1, 0.005, 134, Math.random() * 30 + 151, 181, 2.5, 0.5));
        }
      }
      /* this.datas.players[i].pos.x+=(this.datas.players[i].vel.x*(time/20))
      this.datas.players[i].pos.y+=(this.datas.players[i].vel.y*(time/20))
      if (this.datas.players[i].pos.x-this.datas.players[i].radius<0) {
        this.datas.players[i].pos.x=this.datas.players[i].radius
      }
      if (this.datas.players[i].pos.y-this.datas.players[i].radius<0) {
        this.datas.players[i].pos.y=this.datas.players[i].radius
      }
      if (this.datas.players[i].pos.x+this.datas.players[i].radius>this.datas.areaSize.x) {
        this.datas.players[i].pos.x=this.datas.areaSize.x-this.datas.players[i].radius
      }
      if (this.datas.players[i].pos.y+this.datas.players[i].radius>this.datas.areaSize.y) {
        this.datas.players[i].pos.y=this.datas.areaSize.y-this.datas.players[i].radius
      } */
    }
    this.camera.x = states.datas.players[states.datas.infos.id].pos.x;
    this.camera.y = states.datas.players[states.datas.infos.id].pos.y;
    // this.camera.x += (states.datas.players[states.datas.infos.id].pos.x-this.camera.x)/10;
    // this.camera.y += (states.datas.players[states.datas.infos.id].pos.y-this.camera.y)/5;
  };
  obj.updatePlayerList = function (m) {
    this.datas.playerList = m;
    obj.leaderboard.leaderboard.height = Object.keys(m).length * 20 + 17 * 2;
    let leaderboardHtml = "";
    for (var i in m) {
      let secondClass = "normal";
      if (m[i][2]) {
        secondClass = "dead";
      } 
      leaderboardHtml += `<span class='leaderboard leaderboard-${secondClass}'>${m[i][0]}: ${m[i][1]}</span >`;
      leaderboardHtml += "<br>";
    }
    obj.leaderboard.leaderboard.innerHTML = leaderboardHtml;
  }
  obj.updateStates = function (m) {
    this.datas.infos = m.infos;
    this.datas.players = m.players;
    //console.log(this.datas.playerList, m.playerList)
    // PLAYER ARRAY UPDATE
    // if (!cmpArray(this.datas.playerList || ["a"], m.playerList || ["b"])) {
    //   obj.leaderboard.leaderboard.height = m.playerList.length * 20 + 17 * 2;
    //   let leaderboardHtml = "";
    //   for (var i in m.playerList) {
    //     if (i != 0) leaderboardHtml += "<br>";
    //     let secondClass = "normal";
    //     if (m.playerList[i][2]) {
    //       secondClass = "dead";
    //     } else if (m.playerList[i][3]) {
    //       secondClass = "frozen";
    //     }
    //     leaderboardHtml += `<span class='leaderboard leaderboard-${secondClass}'>${m.playerList[i][0]}: ${m.playerList[i][1]}</span >`;
    //   }
    //   obj.leaderboard.leaderboard.innerHTML = leaderboardHtml;
    // }
    
    this.datas.particles = m.particles;
    this.datas.entities = m.entities;
    for (let i in this.datas.particles) {
      let particule = this.datas.particles[i];
      if (particule.type == 'shrinking') {
        for (let j = 0; j < 13; j++) {
          states.particles.push(new Particle('circle', 1000000, particule.x, particule.x, particule.y, particule.y, Math.PI, Math.PI * 2, 0.5, 1, 0.9, 1, 0.01, 140, 0, 170, 2, -0.1));
        }
      }
      if (particule.type == 'refuel') {
        for (let i = 0; i < 10; i++) {
          states.particles.push(new Particle('circle', 1000000, particule.x - 2, particule.x + 2, particule.y - 2, particule.y + 2, 0, 0, 0, 0, 0, 1, 0.03, 255, 255, 0, 1.5, 0));
        }
      }
      if (particule.type == 'explosion') {
        states.particles.push(new Particle('circle', 1000000, particule.x, particule.x, particule.y, particule.y, 0, 0, 0, 0, 1, 1, 0.08, 110, 110, 110, 0, 3.5));
      }
      if (particule.type == 'dash') {
        for (let i = 0; i < 2; i++) {
          states.particles.push(new Particle('circle', 1000000, particule.x, particule.x, particule.y, particule.y, particule.dir + Math.PI - 0.1, particule.dir + Math.PI + 0.1, 0.4, 0.5, 1, 1, 0.08, 79, 214, 156, 3, 0.2));
        }
      }
      if (particule.type == 'bombExplosion') {
        for (let i = 0; i < 40; i++) {
          let dir = Math.random() * Math.PI * 2;
          let length = Math.random() * particule.region;
          let posX = particule.x + Math.cos(dir) * length;
          let posY = particule.y + Math.sin(dir) * length;
          states.particles.push(new Particle('circle', 1000000, posX, posX, posY, posY, dir, dir, 0.1, 0.1, 1, 0.5, 0.01, 200, 0, 0, 2, -0.01));
        }
      }
      if (particule.type == 'healing') {
        for (let i = 0; i < 2; i++) {
          states.particles.push(new Particle('circle', 1000000, particule.x - 1, particule.x + 1, particule.y - 1, particule.y + 1, 0, 0, 0, 0, 1, 1, 0.08, 119, 205, 92, 1.5, -0.1));
        }
      }
    }
  };
  obj.initMap = function (m) {
    delete this.datas.objects;
    this.datas.areaSize = m.areaSize;
    this.datas.objects = {};
    //console.log(JSON.stringify(m.objects));
    for (let i = 0; i < m.objects.length; i++) {
      m.objects[i].type = objectTypeMap[m.objects[i].type] || m.objects[i].type;
      if (!(m.objects[i].type in this.datas.objects)) {
        this.datas.objects[m.objects[i].type] = {};
      }
      this.datas.objects[m.objects[i].type][m.objects[i].id] = m.objects[i];
    }
    //console.log(JSON.stringify(this.datas.objects))
    if (m.backgroundColor == "rainbow") {
      this.datas.backgroundColor = ["hsl", 0];
    }
    else {
      this.datas.backgroundColor = m.backgroundColor;
    }
    this.datas.areaColor = m.areaColor;
  };
  obj.updateMap = function (m) {
    for (let i in m.update) {
      m.update[i].type = objectTypeMap[m.update[i].type] || m.update[i].type;
      if (m.update[i].type in this.datas.objects && m.update[i].id in this.datas.objects[m.update[i].type]) {
        for (let j in m.update[i]) {
          this.datas.objects[m.update[i].type][m.update[i].id][j] = m.update[i][j];
        }
      }
    }
    //console.log(JSON.stringify(m.add));
    for (let i in m.add) {
      m.add[i].type = objectTypeMap[m.add[i].type] || m.add[i].type;
      if (!(m.add[i].type in this.datas.objects)) {
        this.datas.objects[m.add[i].type] = {};
      }
      this.datas.objects[m.add[i].type][m.add[i].id] = m.add[i];
    }
    for (let i in m.remove) {
      m.remove[i].type = objectTypeMap[m.remove[i].type] || m.remove[i].type;
      delete this.datas.objects[m.remove[i].type][m.remove[i].id];
    }
  };
  obj.changeMap = function () {
    if (!window.roomTransition) return;
    transition = 0;
  };
  return obj;
}

let lastRender = 0;

function animate(time) {
  let progress = time - lastRender;
  let now = performance.now();
  while (times.length > 0 && times[0] <= now - 1000) {
    times.shift();
  }
  times.push(now);
  window.fps = times.length;
  if (!window.inMenu) {
    transition += progress;
    let aimNow = [Math.round(((mousePos.x - width / 2) / fov) + states.datas.players[states.datas.infos.id].pos.x), Math.round(((mousePos.y - height / 2) / fov) + states.datas.players[states.datas.infos.id].pos.y)];
    if (aimNow[0] != previousAimPos[0] || aimNow[1] != previousAimPos[1]) {
      ws.send(msgpack.encode({
        e: 'aim',
        m: [Math.round(((mousePos.x - width / 2) / fov) + states.datas.players[states.datas.infos.id].pos.x), Math.round(((mousePos.y - height / 2) / fov) + states.datas.players[states.datas.infos.id].pos.y)],
      }));
    }
    previousAimPos = aimNow;
    states.update(progress);
    if (transition >= transitionTime / 2) {
      render(states);
    }
  }
  if (transition < transitionTime) {
    context.beginPath();
    context.fillStyle = `rgba(0, 0, 0, ${- Math.abs(transition / (transitionTime / 2) - 1) + 1})`;
    context.fillRect(0, 0, width, height);
    context.closePath();
  }
  lastRender = time;
  window.requestAnimationFrame(animate);
}
window.requestAnimationFrame(animate);
